@import "colors";
@import "shadows";



.header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 900;
  background: #060606;

  .headerLegend{
    font-size: .55em;
    padding:0 8px;
    margin: 0px;
    line-height: 100% !important;
    text-decoration: none;
  }

  .header-container {
    padding: 0 5px;
    display: flex;
    // flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    width: 98%;
    margin: 0 1%;
    color: $background;
    // border:1px solid red;
    // position: relative;
  }

  .header-logo {
    display: flex;
    align-items: flex-end;
    height: 45px;
    margin-left: 5px;
    z-index: 400;

    img {
      height: 45px;
    }
  }

  .top-menu-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    // border:1px solid red;
    z-index: 400;
    // padding:0 5px;
  }

  .btn-content{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .header-logout {
    height: 40px;
    padding: 0px;
    margin-left: 5px;

    img {
      height: 40px;
    }
  }

  .language-btn {
    margin-right: 5px;
    padding: 0 5px;
  }

  .language-btn select {
    border: 0;
    background: transparent;
    color: #ffffff;
    outline: 0;
    padding: 10px;
  }

  .language-btn option {
    border: 0;
    background: #565656;
    color: #ffffff;
    padding: 10px;
  }
}

.header-spacer {
  padding-top: 40px;
}

.header .user {
  margin-right: 0px;
  // border:1px solid red;
  // white-space: nowrap;
  // width:100%;
  @include text-shadow-level(1);
}

.center-inner {
  height: 100%;
  vertical-align: middle;
  display: flex;
  // border:1px solid red !important;
  // height:100px;
  // width:100px;
  background: 0 0;
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  text-align: center;
  color: #fff !important;
}

.subscription-plan .header .center-inner {
  display: none;
}

.subscription-plan .header .btn-success {
  display: none;
}

.subscription-plan .header .SocialMediaHeader {
  display: none;
}

.order-completed .header .center-inner {
  display: none;
}

.order-completed .header .btn-success {
  display: none;
}

.order-completed .header .SocialMediaHeader {
  display: none;
}
.invoice-number{
  padding: 20px 0;
  margin: 10px 0;
  background: $accent-2;
  font-size: larger;
}

.header {
  .main-nav-menu {
    height: 100%;
    width: 100%;
    // border:1px solid green !important;
    left: 0;
    text-align: center;
    z-index: 1;
    color: #fff !important;
    font-size: 18px;
    font-family: Raleway;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    // position: relative;
    // top: -10px;
    display: flex;
    align-items: center;
  }
}

.menu {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  background: 0 0;
  border: 0;
  // margin-block-start: 0.5em;
  color: #fff !important;

  .menu-item {
    position: relative;
    height: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: right;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    a {
      color: #fff;
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      height: 100%;
      position: relative;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
      letter-spacing: 1px;
      border-style: solid;
      border-width: 0;
      border-color: transparent;
      background-color: transparent;
      cursor: pointer;
      // border:1px solid red !important;
      // padding:10px 0;

      .item-outer {
        display: block;
        position: relative;


        // top: 50%;
        // -webkit-transform: translateY(-50%);
        // -moz-transform: translateY(-50%);
        // transform: translateY(-50%);
        .item-inner {
          display: inline-block;
          padding: 0 10px;
          border-width: 0;
          position: relative;
          border-color: transparent;
          background-color: transparent;
          @include text-shadow-level(1);
        }
      }
    }

    .plus {
      display: inline-block;
      position: relative;
      top: 2px;
      right: 10px;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: 1em;
      @include text-shadow-level(1);
    }

    .menu-second {
      // left: 0;
      top: 100%;
      right: 0px;
      position: absolute;
      display: block;
      visibility: hidden;
      overflow: hidden;
      opacity: 0;
      z-index: 10;
      visibility: visible;
      overflow: visible;
      opacity: 1;
      height: 239;
      z-index: 1000;
      background-color: #0a2942;
      @include shadow-level(1);

      ul {
        margin: auto;
        padding: 10px;
        display: grid;
        cursor: pointer;

        li {
          cursor: pointer;
        }
      }
    }

    .menu-inner:active {
      -moz-transition: all 0.25s;
      -webkit-transition: all 0.25s;
      transition: all 0.25s;
    }
  }
}

.edgtf-item-outer:hover {
  opacity: 0.6;
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  cursor: pointer;
}

.header-top-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.header-top-ecoach{
  display: flex;
  font-size: .9em;
  justify-content: flex-end;
  align-items: center;
  padding:4px;
  margin:0 1%;
  a{
    padding:0px 5px;
    margin:0px 5px;
    cursor: pointer;
    &:hover{
      cursor: pointer;
    }
    &:active{
      cursor: pointer;
    }
  }
}

.header-top-band{
  display: flex;
  font-size: .9em;
  justify-content: flex-end;
  align-items: center;
  padding:4px;
  margin:0 1%;
  border-bottom: 1px solid $main;
  a{
    padding:0px 5px;
    margin:0px 5px;
    cursor: pointer;
    &:hover{
      cursor: pointer;
    }
    &:active{
      cursor: pointer;
    }
  }
}