@import "colors";

.readingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: scroll;
  z-index: 2;
}
.manual-container {
  position: absolute;
  width: 100%;
  height: calc(100% - 80px);
  z-index: -1;
  top: 40px;
  .epub-container {
    position: relative;
    height: calc(100% - 85px) !important;
    padding: 10px !important;
  }
}
.optionsContainer {
  position: absolute;
  background-color: #f1f1f1;
  width: 100%;
  height: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 10;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  div {
    display: flex;
  }
  .optionsBtn {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    cursor: pointer;
    &.bookmarked {
      .material-icons {
        color: red;
      }
    }
    &:hover {
      background-color: darken($background, 5%);
    }
    &:active {
      background-color: darken($background, 5%);
      box-shadow: inset 7px 7px 15px -7px rgba(0, 0, 0, 0.7);
    }
  }
  .audioBar {
    max-width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    cursor: pointer;
    display: flex;
    svg {
      display: block;
      width: 100%;
    }
    img {
      // border:1px solid red;
      width: 100%;
      display: block;
    }
  }
  .exitBtn {
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    cursor: pointer;
    padding: 0px 5px;
    text-decoration: none;
    color: black;
    &:hover {
      background-color: darken($background, 5%);
    }
    &:active {
      background-color: darken($background, 5%);
      box-shadow: inset 7px 7px 15px -7px rgba(0, 0, 0, 0.7);
    }
  }
}

.optionsCard {
  position: absolute;
  top: 45px;
  right: 10px;
  height: auto;
  padding-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: $background;
  border-radius: 4px;
  z-index: 20;
  min-width: 400px;
  padding: 0;
  &::after {
    font-family: "Material Icons";
    content: "\f09b";
    font-size: 24px;
    color: $background;
    z-index: -2;
    display: block;
    position: absolute;
    top: -14px;
    right: 25px;
  }
  ul {
    padding: 0 0 0 5px;
    margin-bottom: 0;
    max-height: 75vh;
    overflow-y: scroll;
    li {
      border-bottom: 1px solid lighten($main, 60%);
      min-width: 250px;
      padding: 4px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: -5px;
      &:nth-child(even) {
        background-color: lighten($main, 78%);
      }
      .hLocation {
        cursor: pointer;
        height: 100%;
        width: 100%;
      }
      .removeHighlight {
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: flex-end;
        border: 1px solid lighten($main, 80%);
        background: none;
        border-radius: 6px;
        .material-icons {
          color: lighten($main, 20%);
          font-size: 18px;
        }
      }
      .commentHighlight {
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: flex-end;
        border: 1px solid lighten($main, 80%);
        background: none;
        border-radius: 6px;

        .material-icons {
          color: $lightgray; // lighten($lightgray, 100%);
          font-size: 18px;
          &.bookmarked {
            color: red;
          }
        }
      }
    }
  }
  .optionsNav {
    display: flex;
    flex-direction: row;
    background: $lightgray;
    height: fit-content;

    button {
      padding: 0 8px;
      margin: 6px 0;
      display: flex;
      align-items: center;
      font-size: 0.8em;
      border: 0;
      background: $lightgray;
      border-right: 1px solid $main;
      color: lighten($main, 20%);
    }
    button:last-child {
      border-right: 0;
    }
    .selected {
      background: lighten($main, 60%);
      font-weight: 600;
      position: relative;
      color: $main;
      &:after {
        content: "";
        height: 0;
        width: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid lighten($main, 60%);
        position: absolute;
        bottom: -12px;
        left: 20%;
        margin: 0 0 0 -3px;
        z-index: 100;
      }
    }
  }
  .optionsHeader {
    border-top: 1px solid $accent-2;
    background: $primary;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    // height: 40px;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .titlee {
      padding: 4px;
      color: white;
      font-weight: 500;
      font-size: large;
      padding-left: 10px;
    }
    .removeHighlight {
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      background: none;
      border: 0;
      .material-icons {
        color: white;
        font-size: 18px;
      }
    }
  }
  .fontBtnContainer {
    border-top: 1px solid $accent-2;
    border-bottom: 1px solid $accent-2;
    // height: 40px;
    width: auto;
    display: flex;
    justify-content: space-around;
    .btnFontSm {
      border-right: 1px solid $accent-2;
      height: 40px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .material-icons {
        font-size: 18px;
      }
    }
    .btnFontLg {
      border-left: 1px solid darken($accent-2, 5%);
      height: 40px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .material-icons {
        font-size: 30px;
      }
    }
  }
  .optionsFooter {
    border-top: 1px solid $accent-2;
    border-bottom: 1px solid $accent-2;
    width: auto;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
  }
  .themBtn1 {
    width: 40px;
    height: 40px;
    display: block;
    border: 1px solid lighten($main, 70%);
    background-color: $background;
    margin: 10px;
    border-radius: 50%;
    cursor: pointer;
    &.selected {
      border: 2px solid $main;
    }
  }

  .themBtn2 {
    width: 40px;
    height: 40px;
    display: block;
    border: 1px solid lighten($main, 70%);
    background-color: #ffeacb;
    margin: 10px;
    border-radius: 50%;
    cursor: pointer;
    &.selected {
      border: 2px solid #704214;
    }
  }
  .themBtn3 {
    width: 40px;
    height: 40px;
    display: block;
    border: 1px solid lighten($main, 70%);
    background-color: $main;
    margin: 10px;
    border-radius: 50%;
    cursor: pointer;
    &.selected {
      border: 2px solid lighten($main, 50%);
    }
  }
}
.prevPage {
  position: fixed;
  left: 0px;
  top: 40px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: calc(100% - 80px);
  cursor: pointer;

  background: rgba(150, 150, 150.04);
  background: linear-gradient(
    90deg,
    rgba(150, 150, 150, 0.3) 0%,
    rgba(255, 255, 255, 0) 20%
  );
  &:hover {
    .material-icons {
      color: lighten($main, 30%);
    }
  }
  &:active {
    .material-icons {
      color: lighten($main, 60%);
    }
  }
}
.nextPage {
  position: fixed;
  right: 0px;
  top: 40px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: calc(100% - 80px);
  cursor: pointer;

  background: rgba(150, 150, 150.04);
  background: linear-gradient(
    -90deg,
    rgba(150, 150, 150, 0.3) 0%,
    rgba(255, 255, 255, 0) 20%
  );
  &:hover {
    .material-icons {
      color: lighten($main, 30%);
    }
  }
  &:active {
    .material-icons {
      color: lighten($main, 60%);
    }
  }
}

.highlight-menu {
  border-bottom: 1px solid $lightgray;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .highlight-items {
    flex: 1;
  }

  button {
    border: 0;
    border-right: 1px solid $lightgray;
    background: none;
    cursor: pointer;
    padding: 0 10px;
    transition: all 0.25s;

    &:nth-child(2) {
      border-right: 0;
    }
    &:hover {
      font-weight: 700;
      transition: all 0.25s;
    }
  }
  .selected {
    font-weight: 700;
  }
  .btn-success {
    display: flex;
    align-self: flex-end;
  }
}
.highlightLocation {
  border-bottom: 1px solid $lightgray;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 10px 0;
  .highlightText {
    border-left: 4px solid $success;
    padding: 10px;
  }
  .highlightDelete {
    text-align: center;
    align-items: center;
    justify-content: center;

    span {
      padding: 10px;
      cursor: pointer;
    }
  }
  .highlightGroup {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
  }
}

.pageLocation {
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 40px;
  bottom: 0px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressBar {
  width: 80%;
  height: 4px;
  display: flex;
  justify-content: flex-start;
  background-color: $accent-3;
  border-radius: 2px;
  overflow: hidden;
  // border:1px solid red;
  .progressMade {
    background-color: $secondary;
    height: 4px;
  }
}
.progressPercent {
  flex-direction: row;
  font-size: 0.8em;
  span {
    padding: 2px;
  }
}

.sentEmailBackground {
  position: absolute;
  background-color: rgba(133, 127, 127, 0.329);
  width: 100%;
  height: 100%;
}
.sendEmailWrapper {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  .sendEmail {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    width: 205px;
    height: 100px;
    padding: 10 px;
  }
  .removeHighlight {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: flex-end;
    justify-content: flex-end;
    background: none;
    border: 0;
    .material-icons {
      color: white;
      font-size: 18px;
    }
  }
}
@media (max-width: 480px) {
  .optionsCard {
    min-width: 90%;
  }
}

.AudioyellowBar {
  fill: none;
  stroke: #ffcc00;
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
  stroke-width: 60%;
}

@keyframes write {
  100% {
    stroke-dashoffset: 0;
  }
}
