@import "colors";
.rating {
  width: auto;
  // margin: -4px 0 0 0;
  font-size: 4em;
  line-height: 10%;
  overflow: hidden;
  // border:1px solid red;
  // height: 25px;
  position: relative;
}
.rating input {
  float: right;
  opacity: 0;
  // position: absolute;
  top: 0;
  // border:1px solid red;
}
.rating div,
.rating label {
  float: right;
  color: $background;
  text-decoration: none;
  -webkit-transition: color 0.4s;
  -moz-transition: color 0.4s;
  -o-transition: color 0.4s;
  transition: color 0.4s;
  i.material-icons {
    content: "star_border";
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 35px;
    &:before,
    .mi.star:before {
      position: absolute;
      color: $main;
      content: "\e83a";
    }
  }
}
.rating label:hover ~ label,
.rating input:focus ~ label,
.rating label:hover,
.rating div:hover,
.rating div:hover ~ div,
.rating div.selected {
  color: $secondary;
  cursor: pointer;
  i.material-icons {
    color: $secondary;
    content: "star_border";
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 35px;
    &:before,
    .mi.star:before {
      color: $secondary;
      content: "\e838";
    }
  }
}
.rating2 {
  direction: rtl;
  display: flex;
}
.rating2 div {
  float: none;
}
