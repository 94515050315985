@import "colors";

@font-face {
  font-family: segoeui;
  src: url("../assets/fonts/segoeui.ttf");
}
@font-face {
  font-family: Segoe UI Light;
  src: url("../assets/fonts/segoeuil.ttf");
}
//--------------------Footer-----------------------//
.footer {
  border-top: 1px solid #cccccc;
  padding: 20px;
  background: $main;
  a{
    text-decoration: none;
    cursor: pointer;
  }
}
.footerLegend{
  font-size: .6em;
  padding:0 5px;
}

.footerLogo {
  max-height: 50px;
  max-width: 150px;
}

.home-pic {
  animation: blur 4s ease 0s infinite;
  -webkit-animation: blur 4s ease 0s;
  -moz-animation: blur 4s ease 0s infinite;
  animation-iteration-count: 1;
}

@keyframes blur {
  0% {
    opacity: 0;
    -webkit-filter: blur(40px);
    -moz-filter: blur(40px);
    -o-filter: blur(40px);
    -ms-filter: blur(40px);
  }
  90% {
    opacity: 1;
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
  }
}
.no-underlined {
  text-decoration: none;
}

.bottom-menu {
  width: 100%;
  padding: 10px 0;
  background: $primary;
  z-index: 1;
  color: white;
}
.top-header {
  z-index: 1;
  width: 100%;
  padding: 10px 0;
  background: $primary;
  position: fixed;
  top: 0;
  left: 0;
}
.top-header img {
  height: 30px;
}
.home-center {
  width: 55%;
  margin: 0 auto;
}
.home-center p {
  text-align: center;
}
.home-center-modal {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
}
.home-center-modal p {
  text-align: center;
}
.right {
  float: right;
}
.subtitle {
  display: block;
  color: #5c5c5c;
  font-size: smaller;
  font-weight: 500;
}
.home-center h2 {
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-style: italic;
  color: $primary;
  font-size: large;
}
.main-container {
  z-index: 0;
}
.main-container-modal {
  z-index: 0;
  .close-btn {
    padding: 10px;
  }
}

.block-holder {
  display: block;
  width: 100%;
  height: auto;
  padding: 20px 0;
  min-height: 200px;
}
.grey-bg {
  background: #e9e9e9;
  padding: 10px;
  border-radius: 10px;
}
.download-content {
  text-align: center;
}
.download-content p {
  font-size: x-large;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-style: italic;
}
.app-content {
  text-align: center;
  min-height: 150px;
}
.app-content p {
  font-size: x-large;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-style: italic;
  color: $primary;
}
.fifty {
  width: 45% !important;
  vertical-align: top;
  display: inline-block;
}
.video-container {
  background: #f6f6f6;
  border: 1px solid #d6d6d6;
  position: relative;
  padding-top: 48.25%;
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.button-holder {
  margin: 10px 0;
}
.bigger-bullets {
  font-size: x-large;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-style: italic;
}
.blue-bullets {
  width: 100%;
  margin: 0 auto;
  list-style-type: none;
  margin-bottom: 30px;
}
.blue-bullets h2 {
  margin-left: -20px;
}
.blue-bullets li {
  margin-bottom: 10px;
}
.blue-bullets li::before {
  content: "\2022";
  color: $primary; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
.progress {
  background: #bababa;
  padding: 5px;
  border-radius: 30px;
  text-align: center;
  margin: 10px 0 40px 0;
}
.progress-text {
  vertical-align: middle;
  font-weight: 900;
  display: inline-block;
  margin: 0 10px;
}
.progressed {
  background: #f9ce2f !important;
}
.progress1 {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #9b9a9a;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  width: 50px;
  height: 20px;
  padding: 5px;
  background: $background;
  text-align: center;
}
.progress2 {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #9b9a9a;
  width: 200px;
  height: 30px;
  background: $background;
  text-align: center;
  position: relative;
}
.parts-text {
  position: absolute;
  left: 5px;
  top: 5px;
}
.parts30 {
  width: 33%;
  height: 30px;
  border-right: 1px dashed #cccccc;
  vertical-align: middle;
  display: inline-block;
}
.parts30:last-child {
  border-right: 0;
}
.parts50 {
  width: 49.5%;
  height: 30px;
  border-right: 1px dashed #cccccc;
  vertical-align: middle;
  display: inline-block;
}
.parts50:last-child {
  border-right: 0;
}
.progress3 {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #9b9a9a;
  width: 100px;
  height: 20px;
  padding: 5px;
  background: $background;
  text-align: center;
}
.progress4 {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #9b9a9a;
  width: 100px;
  height: 30px;
  background: $background;
  text-align: center;
  position: relative;
}
.progress5 {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #9b9a9a;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  width: auto;
  height: 20px;
  padding: 5px;
  background: $background;
  text-align: center;
}
.button-holder {
  display: block;
  height: 50px;
  width: 100%;
}
.button-holder-center {
  height: 50px;
  width: 100%;
}

.logindiv {
  // border-radius: 5px;
  text-align: center;
  background-color: rgba($main, 0.8);
  transform: scale(1);
  padding-top: 15px;
  color: $accent;
  font-family: "Roboto", sans-serif;
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.75);
  font-size: 16px !important;
  .btn-flat-primary {
    background-color: rgba($main, 0);
    margin-top: 5px;
    font-size: 0.95rem;
    &:hover {
      background-color: rgba($main, 0.5);
    }
  }
}

.login-page {
  width: 500px;
  margin: 0 auto;
  margin-top: 110px;
}

.LogoLogin {
  width: 30%;
  margin-bottom: 50px;
  margin-top: 10px;
}

.row-fluid {
  width: 100%;
  zoom: 1;
}
.row-fluid:before,
.row-fluid:after {
  display: table;
  line-height: 0;
  content: "";
}
.row-fluid:after {
  clear: both;
}
.row-fluid [class*="span"] {
  display: block;
  float: left;
  width: 100%;
  min-height: 30px;
  margin-left: 2.564102564102564%;
  margin-left: 2.5109110747408616%;
  box-sizing: border-box;
}
.row-fluid [class*="span"]:first-child {
  margin-left: 0;
}
.row-fluid .controls-row [class*="span"] + [class*="span"] {
  margin-left: 2.564102564102564%;
}
.row-fluid .span12 {
  width: 100%;
}
.row-fluid .span11 {
  width: 91.45299145299145%;
}
.row-fluid .span10 {
  width: 82.90598290598291%;
  *width: 82.8527914166212%;
}
.row-fluid .span9 {
  width: 74.35897435897436%;
  *width: 74.30578286961266%;
}
.row-fluid .span8 {
  width: 65.81196581196582%;
  *width: 65.75877432260411%;
}
.row-fluid .span7 {
  width: 57.26495726495726%;
  *width: 57.21176577559556%;
}
.row-fluid .span6 {
  width: 48.717948717948715%;
  *width: 48.664757228587014%;
}
.row-fluid .span5 {
  width: 40.17094017094017%;
  *width: 40.11774868157847%;
}
.row-fluid .span4 {
  width: 31.623931623931625%;
  *width: 31.570740134569924%;
}
.row-fluid .span3 {
  width: 23.076923076923077%;
  *width: 23.023731587561375%;
}
.row-fluid .span2 {
  width: 14.52991452991453%;
  *width: 14.476723040552828%;
}
.row-fluid .span1 {
  width: 5.982905982905983%;
  *width: 5.929714493544281%;
}
.row-fluid .offset12 {
  margin-left: 105.12820512820512%;
  *margin-left: 105.02182214948171%;
}
.row-fluid .offset12:first-child {
  margin-left: 102.56410256410257%;
  *margin-left: 102.45771958537915%;
}
.row-fluid .offset11 {
  margin-left: 96.58119658119658%;
  *margin-left: 96.47481360247316%;
}
.row-fluid .offset11:first-child {
  margin-left: 94.01709401709402%;
  *margin-left: 93.91071103837061%;
}
.row-fluid .offset10 {
  margin-left: 88.03418803418803%;
  *margin-left: 87.92780505546462%;
}
.row-fluid .offset10:first-child {
  margin-left: 85.47008547008548%;
  *margin-left: 85.36370249136206%;
}
.row-fluid .offset9 {
  margin-left: 79.48717948717949%;
  *margin-left: 79.38079650845607%;
}
.row-fluid .offset9:first-child {
  margin-left: 76.92307692307693%;
  *margin-left: 76.81669394435352%;
}
.row-fluid .offset8 {
  margin-left: 70.94017094017094%;
  *margin-left: 70.83378796144753%;
}
.row-fluid .offset8:first-child {
  margin-left: 68.37606837606839%;
  *margin-left: 68.26968539734497%;
}
.row-fluid .offset7 {
  margin-left: 62.393162393162385%;
  *margin-left: 62.28677941443899%;
}
.row-fluid .offset7:first-child {
  margin-left: 59.82905982905982%;
  *margin-left: 59.72267685033642%;
}
.row-fluid .offset6 {
  margin-left: 53.84615384615384%;
  *margin-left: 53.739770867430444%;
}
.row-fluid .offset6:first-child {
  margin-left: 51.28205128205128%;
  *margin-left: 51.175668303327875%;
}
.row-fluid .offset5 {
  margin-left: 45.299145299145295%;
  *margin-left: 45.1927623204219%;
}
.row-fluid .offset5:first-child {
  margin-left: 42.73504273504273%;
  *margin-left: 42.62865975631933%;
}
.row-fluid .offset4 {
  margin-left: 36.75213675213675%;
  *margin-left: 36.645753773413354%;
}
.row-fluid .offset4:first-child {
  margin-left: 34.18803418803419%;
  *margin-left: 34.081651209310785%;
}
.row-fluid .offset3 {
  margin-left: 28.205128205128204%;
  *margin-left: 28.0987452264048%;
}
.row-fluid .offset3:first-child {
  margin-left: 25.641025641025642%;
  *margin-left: 25.53464266230224%;
}
.row-fluid .offset2 {
  margin-left: 19.65811965811966%;
  *margin-left: 19.551736679396257%;
}
.row-fluid .offset2:first-child {
  margin-left: 17.094017094017094%;
  *margin-left: 16.98763411529369%;
}
.row-fluid .offset1 {
  margin-left: 11.11111111111111%;
  *margin-left: 11.004728132387708%;
}
.row-fluid .offset1:first-child {
  margin-left: 8.547008547008547%;
  *margin-left: 8.440625568285142%;
}

.regularTxt input {
  width: 80%;
  margin-bottom: 10px;
}

.regularTxt input[type="text"] {
  height: 30px !important;
  display: inline-block;
  height: 100%;
  background: $background;
  color: #525252;
  border: 1px solid #525252;
  text-align: left;
  font-size: 16px !important;
  padding-left: 5px;
  width: 90%;
}

.loginTxt input {
  width: 80%;
  margin-bottom: 10px;
}

.loginTxt input[type="text"] {
  height: 30px !important;
  display: inline-block;
  height: 100%;
  background: $background;
  color: #525252;
  border-right: 0;
  border-top: 0;
  border-left: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  text-align: center;
  font-size: 16px !important;
}

.loginTxt input[type="password"] {
  height: 30px !important;
  display: inline-block;
  height: 100%;
  background: $background;
  color: #525252;
  border-right: 0;
  border-top: 0;
  border-left: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  text-align: center;
  font-size: 16px !important;
}

.form-actions {
  padding: 19px 20px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: $background;
  zoom: 1;
}

.form-actions:before,
.form-actions:after {
  display: table;
  line-height: 0;
  content: "";
}

.form-actions:after {
  clear: both;
}

.loginButtonContainer {
  background-color: transparent;
}

.loginCenterButton {
  text-align: center;
}

.loginButtonContainer {
  background-color: transparent;
}

.badLogin {
  color: $warning !important;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  background-color: $accent-3 !important; 
  border-radius: 4px;
  width: 85%;
  margin: 0 auto;
  margin-top: 15px;
  padding: 3px;
}
.bottom-login {
  width: 100%;
  text-align: center;
  background-color: rgba(darken($primary, 5%), 0.6);
  padding: 10px 0 20px 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bottom-login a {
  display: block;
  color: $accent;
  text-decoration: underline;
  font-size: 16px;
  line-height: initial;
  margin-top: 5px;
}

.login-btn {
  border: 0;
  position: relative;
  width: 85% !important;
  margin: 0 0 10px 0;
  padding: 8px 0px;
  color: $main;
  background: $secondary;
  border-radius: 3rem;
  cursor: pointer;
  overflow: hidden;
  transition: width 0.3s 0.15s, font-size 0.1s 0.15s;
  font-size: 16px !important;
}
.hidden {
  display: "none";
}
.center {
  text-align: center;
}
.method {
  display: inline-block;
  padding: 5px;
  width: 20%;
  vertical-align: top;
  background: #005492;
  margin: 20px 0;
  margin-right: 20px;
  color: $background;
  text-align: center;
  min-height: 200px;
  border-radius: 30px;
  text-decoration: none;
}
.method-modal {
  display: inline-block;
  padding: 10px;
  width: 45%;
  background: $primary;
  color: $background;
  text-align: center;
  min-height: 200px;
  border-radius: 30px;
  text-decoration: none;
}
.method h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-style: italic;
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 45px;
  text-decoration: none;
}
.grade .method img {
  width: 80%;
  height: auto;
  margin-top: 40px;
}
.warning-container {
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  background: $warning;
  text-align: center;
  margin: 30px 0;
}
.warning-container img {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  margin-right: 10px;
}
.warning-container p {
  font-size: smaller;
  width: 87%;
  font-weight: bold;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  color: $warning;
  background: $accent-3;
  padding: 15px;
  border-radius: 5px;
}
.login-container {
  position: fixed;
  background-color: $accent;
  background-image: url(../assets/images/bg-for-login.jpg);
  background-size: 100% cover;
  width: 100%;
  height: 100%;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .container-fluid {
    padding: 0;
  }
  .login-page {
    margin-top: 25%;
    padding-top:20px;
    width: 90%;
  }
  .fifty {
    display: block;
    width: 100% !important;
  }
}


.loading-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.loading-icon div {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: $secondary;
  animation: loading 0.8s infinite;
}

.loading-icon div:nth-child(1) {
  animation-delay: 0s;
}

.loading-icon div:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-icon div:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-icon div:nth-child(4) {
  animation-delay: 0.3s;
}

.loading-icon div:nth-child(5) {
  animation-delay: 0.4s;
}

@keyframes loading {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}