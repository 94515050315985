$widths10: 10;

@for $i from 1 through $widths10 {
	.width-#{$i} {
		width: ($i) * 1px;
	}
}

$widths: 1000;

@for $i from 1 through $widths {
  .width-#{$i *5} {
    width: ($i) * 5px;
  }
  .min-width-#{$i *5} {
    min-width: ($i) * 5px;
  }
}

$widthsP: 20;

@for $i from 1 through $widthsP {
  .width-#{$i * 5}p {
    width: ($i) * 5%;
  }
}

.width-100p {
	width:100%;
}

.width-25 {
	width:25px;
}

.height-100p {
	height:100%;
}

$heights10: 10;

@for $i from 1 through $heights10 {
	.height-#{$i} {
		height: ($i) * 1px;
	}
}


$heights: 200;

@for $i from 3 through $heights {
	.height-#{$i *5} {
		height: ($i) * 5px;
	}
	.min-height-#{$i *5} {
		min-height: ($i) * 5px;
	}
}


$heights: 10;

@for $i from 1 through $heights {
	.height-#{$i *10}p {
		height: ($i) * 10%;
	}
}

.top--30 {
	margin-top:-30px;
}

.top--20 {
	margin-top:-20px;
}

.top-0 {
	margin-top:0px !important;
}

$negativetop: 10;

@for $i from 1 through $negativetop {
	.top--#{$i} {
		margin-top: -($i) * 1px;
	}
}

$top9: 9;

@for $i from 1 through $top9 {
	.top-#{$i} {
		margin-top: ($i) * 1px;
	}
}

$top: 20;

@for $i from 1 through $top {
	.top-#{$i *10} {
		margin-top: ($i) * 10px;
	}
}

.bottom-0 {
	margin-bottom:0px;
}

.bottom-15 {
  margin-bottom: 15px;
}

$bottom9: 9;

@for $i from 1 through $bottom9 {
	.bottom-#{$i} {
		margin-bottom: ($i) * 1px;
	}
}

$bottom: 20;

@for $i from 1 through $bottom {
	.bottom-#{$i *10} {
		margin-bottom: ($i) * 10px;
	}
}

$right: 10;

@for $i from 1 through $right {
	.right-#{$i *10} {
		margin-right: ($i) * 10px;
	}
}
@for $i from 1 through $right {
	.right-#{$i} {
		margin-right: ($i) * 1px;
	}
}

.right--15 {
	margin-right: -15px;
}

.right--20 {
	margin-right: -20px;
}

.left--15 {
	margin-left: -15px;
}

.left--20 {
	margin-left: -20px;
}

$left10: 10;

@for $i from 1 through $left10 {
	.left-#{$i} {
		margin-left: ($i) * 1px;
	}
}

$left: 20;

@for $i from 3 through $left {
	.left-#{$i * 5} {
		margin-left: ($i) * 5px;
	}
}

.left-0 {
	margin-left:0px !important ;
}

.right-0 {
	margin-right:0px !important;
}

$padding: 20;

@for $i from 1 through $padding {
	.padding-all-#{$i} {
		padding: ($i) * 1px;
	}

	.padding-top-#{$i} {
		padding-top: ($i) * 1px;
	}

	.padding-bottom-#{$i} {
		padding-bottom: ($i) * 1px;
	}
	.padding-bottom-#{$i*5} {
		padding-bottom: ($i) * 5px;
	}

	.padding-right-#{$i} {
		padding-right: ($i) * 1px;
	}

	.padding-left-#{$i} {
		padding-left: ($i) * 1px;
	}
}

@for $i from 3 through 14 {
	.padding-all-#{$i*10} {
		padding: ($i) * 10px;
	}

	.padding-top-#{$i*10} {
		padding-top: ($i) * 10px;
	}

	.padding-bottom-#{$i*10} {
		padding-bottom: ($i) * 10px;
	}

	.padding-right-#{$i*10} {
		padding-right: ($i) * 10px;
	}

	.padding-left-#{$i*10} {
		padding-left: ($i) * 10px;
	}
}

.padding-all-0 {
	padding:0px !important;
	padding-top:0px !important;
	padding-left:0px !important;
	padding-bottom:0px !important;
	padding-right:0px !important;
}
.w-60{
	width:60%;
	margin: 0 auto;
	text-align: center;
}


@media (min-width: 576px) {
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
}

@media (min-width: 768px) {
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
}

@media (min-width: 992px) {
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
}

@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
}
 .position-b-0{
	bottom: 0;
 }
 .position-l-0{
	left: 0;
 }