@import "colors";

@-webkit-keyframes bounce {
  from,
  1%,
  2%,
  4%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  2%,
  3% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  4% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  5% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounce {
  from,
  1%,
  2%,
  4%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  2%,
  3% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  4% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  5% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.brake {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  .title {
    display: inline-block;
    white-space: nowrap;
    margin: 20px 40px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    color: $primary;
    // &:nth-child(5){
    //   border:1px solid red;
    // }
    a {
      text-decoration: none;
      color: $primary;
    }
  }
  .decoration {
    display: inline-block;
    width: 100%;
    height: 0px;
    border: 1px solid $secondary;
  }
}

.roundbtn {
  display: inline-block;
  background-color: $accent-4;
  color: $background;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 14px 12px;
  margin: 5px 0;
  box-sizing: border-box;
  text-align: center;

  -webkit-animation-name: bounce;
  animation-name: bounce;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

.slide {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
  width: 200px;
  height: 150px;
  position: relative;
  z-index: 98;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
}
