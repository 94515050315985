@import "colors";
@import "shadows";
@import "widthandheights";

.card {
  @include shadow-level(1);
  border-radius: 4px;
  overflow: hidden;
}

.remaining-time {
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 60px;
  display: flex;
  background-color: $background;
  @include shadow-level(2);
  border-radius: 4px 0 0 4px;
  padding: 10px 20px 10px 10px;
  h6{
    margin: 0;
    color: $success;
  }
}

.question {
  padding: 10px 40px 10px 10px;
  background-color: lighten($main, 75%);
  font-size: 1.5em;
  font-weight: bold;
}

.answer {
  input[type="radio"] {
    position: absolute;
    visibility: hidden;

    &:checked ~ label {
      background-color: lighten($secondary, 40%);
      border: 2px solid $secondary;
      color: $main;
      @include shadow-level(2);
      font-weight: bold;
    }
  }

  label {
    cursor: pointer;
    border: 1px solid lighten($main, 50%);
    display: block;
    width: calc(100% - 10px);
    border-radius: 4px;
    @include shadow-level(0);
    padding: 5px;
    margin: 5px;
    transition: all 0.5s ease;
    position: relative;
    text-align: left;
  }
}

.correct-answer {
  .answer {
    input[type="radio"] {
      &:checked ~ label {
        background-color: lighten($success, 60%);
        border: 2px solid $success;
        color: darken($success, 10%);
      }
    }
  }
}

.wrong-answer {
  .answer {
    input[type="radio"] {
      &:checked ~ label {
        background-color: lighten($warning, 60%);
        border: 2px solid $warning;
        color: darken($warning, 10%);
      }
    }
  }
}

.review-quiz {
  margin-top: 10px;
  width: 100%;

  th {
    padding: 10px;
  }

  td {
    padding: 10px;
  }

  td:nth-child(3) {
    min-width: 100px;
  }
  tr:nth-child(even) {
    background: $accent-2;
  }
}

.congratulations-image {
  height: 100%;
  width: 100%;
  background-image: url("../assets/images/QuizCompleted.jpg");
  background-position: center center;
  background-size: cover;
}

.score-rate {
  font-size: 3em;
}

.scorre-stars {
  display: flex;
  justify-content: center;
  color: $secondary;

  .material-icons {
    font-size: 2.5em;
  }
}

.startquiz-image {
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/Studying.jpg");
  background-position: center right;
  background-size: cover;
}
