.modal {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(0, 1, 0, 0.5);
  display: block;
}
.modal-content{
  position: relative;
  .btn-close{
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/.8em auto no-repeat;
    background-color: $background !important;
    position:absolute;
    z-index: 9999;
    border-radius: 50%;
    top:-10px;
    right:-10px;
    opacity: 95%;
  }
}

.modal.open {
  display: block;
}

.modal__header,
.modal__footer {
  height: 100px;
}
.modal__content,
.modal__footer {
  position: absolute;
  width: 100%;
}
.modal__content {
  bottom: 100px;
  top: 100px;
  overflow-y: auto;
}
.modal__footer {
  bottom: 0;
}

.video__iframe {
  display: block;
  margin-top: 20%;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
.content_show {
  color: #ffffff;
  bottom: 50%;
  left: 45%;
  top: auto;
}
