.logo-large{
  display: block;
  visibility: visible;
  // max-width: 200px;
}

.logo-small{
  display: none;
  visibility: hidden;
  max-width: 250px;
}

#menu-toggle {
  display: none;
  visibility: hidden;
}

.menu-button{
  display: none;
  visibility: hidden;
  position: relative;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
  @include shadow-level(1);
  right:0px;
  margin-right: 10px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
  margin-right: 0px;
  position: absolute;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
  margin-right: 0px;
  position: absolute;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}
@media (min-width: 1600px) {
  .main, .main-content{
    width: 80%;
    margin: 0 auto;
  }
  .header-spacer {
    padding-top: 100px;
    width:100%;
    // border:1px solid red;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////max-width: 1199px /////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media all and (max-width: 1199px) {
  .header{
    .header-content{
      .menu{
        .btn{
          padding: 6px 15px;
          font-size: 1.15em;
        }
      }
      .user{
          font-size:1.8em;
          margin-top:10px;
      }
    }
  }

  .courses-list{
    .list-item{
      border-radius: 7px;
      .course-completion{
        .completion{
          color:$main;
          font-size:1em;
        }
      }
    }
  }

  .learn-more-courses{
    .course{
      .preview-card{
        position: relative;
        overflow: visible;
        min-height: 362px;
      
        .upcoming-product{
          position: absolute;
          bottom: 0;
        }
        
        .tooltip-content{
          width: 110%;
          left: calc(125% + 10px);
        }
      }
    }
  }
  
 } 
 .horizontal{
  width: 100%;
  .card{
    padding-left: 21%;
    &.preview-card {
      min-height: 170px;
    }
    .dashPrevThumb {
      display: block;
      width: 20%;
      padding-bottom: 12.5%;
      overflow: hidden;
      position: absolute;
      top:5px;
      left:5px;
    }
    .btn{
      text-align: left;
      display: inline-block;
      width: auto;
      margin:0 5px 0 0; 
    }
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////max-width: 991px //////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media all and (max-width: 991px) { 
  .carousel-control-next{
    right: -20px;
  }
  .carousel-control-prev{
    left: -20px;
  }
  .carousel-control-next-icon, .carousel-control-prev-icon{
    padding: 10px;
  }
  .logo-large{
    display: none;
    visibility: hidden;
  }
  .logo-small{
    display: block;
    visibility: visible;
  }
  
  // .header{
  //   .center-inner{
  //     display: none;
  //   }
  //   .header-content{
  //     .menu{
  //       .btn{
  //         padding: 6px 12px;
  //         font-size: 1.1em;
  //       }
  //     }
  //     .user{
  //         font-size:1.6em;
  //         margin-top:10px;
  //     }
  //   }
  // }
  .brake{
    .title{
      &:nth-child(3){
        display: none;
        visibility: hidden;
      }
    }
  }
  .courses-list{
    .list-item{
      border-radius: 7px;
      .video-icons{
        width:15%;
      }

      .item-description{
        width:45%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;      
        padding: 0px 5px;
        .course-title{
          font-size: 1.1em;
          font-weight: bold;
        }
        .course-description{
          min-height: 50px;
        }
      }
  
      .access-course{
        font-size: .9em;
      }
      .course-completion{
        font-size: .9em;
        .completion{
          font-size:1.5em;
        }
      }
      .course-certificate{
        font-size: .9em;
      }
    }
  }

  .learn-more-courses{
    .course{
      .preview-card{
        position: relative;
        overflow: visible;
        min-height: 360px;
      
        .upcoming-product{
          position: absolute;
          bottom: 0;
        }
        
        .tooltip-content{
          width: 110%;
          left: calc(125% + 10px);
        }
      }
      &:nth-child(4n){
        .preview-card{
          .tooltip-content{
            right: auto;
            left: calc(125% + 10px);
            transform-origin: top left;        
            &::after{
              content: "";
              position: absolute;
              top: calc(50% - 5px);
              left: -10px;
        
              width: 0; 
              height: 0; 
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent; 
              border-left: 5px solid transparent; 
              
              border-right:5px solid $background; 
              
            }  
          }
        }
      } 

      &:nth-child(3n){
        .preview-card{
          .tooltip-content{
            left: auto;
            right: calc(100% + 10px);
            transform-origin: top left;        
            &::after{
              content: "";
              position: absolute;
              top: calc(50% - 5px);
              left: auto;
              right:-10px;
        
              width: 0; 
              height: 0; 
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent; 
              border-right:5px solid transparent; 

              border-left:5px solid $background; 
              
            }  
          }
        }
      } 
    }
  }

  .horizontal{
    width: 100%;
    .card{
      padding-left: 21%;
      &.preview-card {
        min-height: 170px;
      }
      .dashPrevThumb {
        display: block;
        width: 20%;
        padding-bottom: 12.5%;
        overflow: hidden;
        position: absolute;
        top:5px;
        left:5px;
      }
      .btn{
        text-align: left;
        display: inline-block;
        width: auto;
        margin:0 5px 0 0; 
      }
    }
  }

 } 
 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////max-width: 768px //////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media all and (max-width: 768px) {
  .pdf-header{
    .top-back-area :nth-child(2){
      display: none;
    }
  }
  .upcoming-icon{
    width: 100%;
  }
  .carousel{
    // display: none;
    width: 100%;
    height: 500px;
    .carousel-inner{
      width: 100%;
      height: 500px;
      .carousel-item{
        height: 500px;
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: rgba(0,0,0,9);
        .carousel-content{
          justify-content: flex-start;
          align-items: flex-end;
        }
        img{
          display: block;
          position: absolute;
          top:0;
          // right:50%;
          // margin-right: -100%;
          height: 500px;
          width: auto !important;
          opacity: .7;
        }
        .carousel-caption{
          width: 85%;
          
          padding:10px;
          h3{
            font-size: 1.9em;
            @include text-shadow-level(2)
          }
        }
      }
    }
  }
  .carousel-small{
    display: block;
  }
  // .header .header-logo{
  //   width: 65px;
  //   overflow: hidden;
  //   position: absolute;
  //   left: 45px;
  // }
  .header{
    .center-inner{
        // display: none;
        order: -1
      }
    .header-content{
      .menu{
        
        .btn{
          padding: 5px 10px;
          font-size: 1.05em;
        }
      }
      .user{
          font-size:1.3em;
          margin-top:5px;
      }
    }
  }
  .tabs{
    margin:10px 5px 0px 5px;
    display: flex;
    .btn.btn-tab{
      padding: 6px 14px;
      box-shadow: none;
      font-size: 1.2em;
      margin-right: 5px;
    }
  }
  .tabs-content{
    box-sizing: border-box;
    padding:10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    flex-wrap: wrap;
    .tab-card{
      width:48%;
      min-height: 100px;
      background-color: $accent-2;
      margin-bottom: 10px;
      margin-right: 10px;
      &:last-child{
        margin-right: 0px;
      }
      .title{
        font-size: 1.2em;
      }
    }
  }
  .brake{
    .title{
      &:nth-child(1){
        display: none;
        visibility: hidden;
      }
      &:nth-child(3){
        display: block;
        visibility: visible;
      }
      &:nth-child(5){
        display: none;
        visibility: hidden;
      }
    }
  }
  
  .courses-list{
    .list-item{
      border-radius: 7px;
      flex-wrap: wrap;
      .video-icons{
        width:40%;
      }
      
      .item-description{
        width:60%;
        padding: 0px 10px;
        .course-description{
          min-height: 20px;
        }
      }
  
      .access-course{
        margin-top:10px;
        width:30%;
      }
      .course-completion{
        width:30%;
        margin-top:10px;
      }
      .course-certificate{
        width:30%;
        margin-top:10px;
      }
    }
  }
  #VideoItem{
    padding-left:10px;
    padding-right:10px;
    width:100%;
  
    .videoTitle{
      background-color: $primary;
      padding: 5px;
      h1{
        font-size:1.5em;
        margin:0;
        color:$background
      }
    }
    
    .video-footer{
      flex-wrap: wrap;
      .quiz-legend{
        width:100%;
      }
      .video-rating{
        width:100%;
      }
    }
    .bottom-section{
      display: flex;
      flex-wrap: wrap;
      .lesson-comments{
        width:100%;
        min-width: 100%;
        .comments-header{
          font-weight: bold;
          font-size: 1.2em;
        }
        textarea{
          width:100%;
          height:80px;
          border-radius: 4px;
          border:1px solid $accent-3;
        }
        .comment-buttons{
          text-align: right;
        }
      }
      .back{
        width:100%;
        order:2;
      }
    }
  }
  .locked{
    .lock-info{
      padding-left: 10px;
      width: 70%;
    }
  }
  .slider h2{
    padding: 10px 10px 0 10px;
    margin-top: 160px;
    background: rgba(0,0,0,.4);
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
  }
  .slider h1{
    padding: 0 10px 10px 10px;
    background: rgba(0,0,0,.4);
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
  }
  .img-thumbnail{
    margin-bottom: 20px;
  }
  .carousel .course .courseTitle h2{
    font-size: 10px;
  }
  .carousel .course .courseTitle p{
    font-size: 10px;
  }
  // .footer {
  //   height: 65px;
  // }
  .footer .col-8{
    display: none;
  }
  .footer .col-1{
    display: none;
  }
  .footer .sectionContent{
    width: 50%;
    margin: 0 auto;
  }

  .menu-button{
    display: block;
    visibility: visible;
    
    
  }
  .menu {
    position: absolute;
    top: 10px;
    margin-top: 85px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .menu-item {
      a{
        width: 100%;
        height: auto;;
      }
      .menu-second {
        left: 0;
        top: 5px;
        right: auto;
        width: 100%;
        position: relative;
        display: block;
        visibility: hidden;
        overflow: hidden;
        opacity: 0;
        z-index: 10;
        visibility: visible;
        overflow: visible;
        opacity: 1;
        height: 239;
        z-index: 1000;
        background-color: #0a2942;
        @include shadow-level(1);
  
        ul {
          margin: auto;
          padding: 10px;
          display: grid;
          cursor: pointer;
  
          li {
            cursor: pointer;
          }
        }
      }
    }
  }
  
  #menu-toggle ~ .menu {
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    height: 0;
    overflow: hidden;
    @include shadow-level(1);
  }
  #menu-toggle:checked ~ .menu {
    height: auto;
  }
  #menu-toggle ~ .menu li {
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    opacity: 1;
    border-bottom: 1px solid #444;
    height: auto;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background: rgba(24, 22, 22, 1);
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }

  .learn-more-courses{
    .course{
      .preview-card{
        .tooltip-content{
          width: 110%;
          left: calc(125% + 10px);
        }
      }
      &:nth-child(4n){
        .preview-card{
          .tooltip-content{
            right: auto;
            left: calc(125% + 10px);
            transform-origin: top left;        
            &::after{
              content: "";
              position: absolute;
              top: calc(50% - 5px);
              left: -10px;
              width: 0; 
              height: 0; 
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent; 
              border-left: 5px solid transparent; 
              
              border-right:5px solid $background; 
              
            }  
          }
        }
      } 

      &:nth-child(3n){
        .preview-card{
          .tooltip-content{
            left: auto;
            right: calc(100% + 10px);
            transform-origin: top left;        
            &::after{
              content: "";
              position: absolute;
              top: calc(50% - 5px);
              left: auto;
              right:-10px;
        
              width: 0; 
              height: 0; 
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent; 
              border-right:5px solid transparent; 

              border-left:5px solid $background; 
              
            }  
          }
        }
      } 

    
    }
  }

} 
.zoom-number{
  display: none;
}
@media all and (max-width: 767px) {
  .learn-more-courses{
    .course{
      .preview-card{
        position: relative;
        overflow: visible;
        min-height: 350px;
      
        .upcoming-product{
          position: absolute;
          bottom: 0;
        }
        
        .tooltip-content{
          width: 110%;
          left: calc(125% + 10px);
        }
      }
      &:nth-child(4n){
        .preview-card{
          .tooltip-content{
            right: auto;
            left: calc(125% + 10px);
            transform-origin: top left;        
            &::after{
              content: "";
              position: absolute;
              top: calc(50% - 5px);
              left: -10px;
              width: 0; 
              height: 0; 
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent; 
              border-left: 5px solid transparent; 
              
              border-right:5px solid $background; 
              
            }  
          }
        }
      } 

      &:nth-child(3n){
        .preview-card{
          .tooltip-content{
            right: auto;
            left: calc(125% + 10px);
            transform-origin: top left;        
            &::after{
              content: "";
              position: absolute;
              top: calc(50% - 5px);
              left: -10px;
              width: 0; 
              height: 0; 
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent; 
              border-left: 5px solid transparent; 
              
              border-right:5px solid $background; 
              
            }  
          }
        }
      } 

      &:nth-child(even){
        .preview-card{
          .tooltip-content{
            left: auto;
            right: calc(100% + 10px);
            transform-origin: top left;        
            &::after{
              content: "";
              position: absolute;
              top: calc(50% - 5px);
              left: auto;
              right:-10px;
        
              width: 0; 
              height: 0; 
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent; 
              border-right:5px solid transparent; 

              border-left:5px solid $background; 
              
            }  
          }
        }
      } 
    }
  }

  .horizontal{
    width: 100%;
    .card{
      padding-left: 0%;
      &.preview-card {
        min-height: 170px;
        padding-top:50%;
      }
      .dashPrevThumb {
        display: block;
        width: calc(100% - 10px);
        padding-bottom: 50%;
        overflow: hidden;
        position: absolute;
        top:5px;
        left:5px;
      }
      .btn{
        text-align: center;
        display: block;
        width: 100%;
        margin:0 0 0 0; 
      }
      
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////max-width: 575px //////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media all and (max-width: 575px) { 
  .carousel-small{
    display: block;

    .img-cours{
      margin: 0 auto;
      display: block;
    }
    .courseTitle {
      margin: 0 auto;
      display: block;
      width: 100%;
      text-align: center;
      padding-bottom: 0;
    }
  }
  .pdf-pagination, .zoom-number{
    display: none;
  }
  h1{
    font-size: 1.3em;
  }
  .courses-list .list-item .item-description h1 i{
    vertical-align: bottom;
  }
  .courses-list .list-item .item-description .videos-ammount {
    :first-child {
      font-size: 15px;
    }

    span {
      font-size: 8px;
    }
  }
  .header-container{
    padding: 10px 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: center;
    width: 98%;
    margin: 0 1%;
    border-bottom: 0.6px solid white;
    color: $background;

  }
  // .header-logo{
  //   width:100%;
  //   min-width: 90px;
  //   display: flex;
  //   justify-content: flex-end;
  // }
  .header .user {
    order: 2;
    display: none;
  }
  .top-menu-right {
    // max-width:230px;
    // border:1px solid black !important;
    padding:0px;
    margin-left: 0px;
  }
  .pdfViewer canvas {
    height: 100vh !important;
    width: 100% !important;
}
  .pager button{
      border: none;
      background: none;
      padding: 5px 0;
      span{
        opacity: 0.6;
      }
    }

  .pdf-header{
    .header-logo{
      width:30px;
      overflow: hidden;
      left: 50px;
      img{
        height: 100%;
        left: 0;
        position: absolute;
      }
    }
    .top-back-area :nth-child(2){
      display: none;
    }
  }
  .pdf-pagination a {
    margin-right: 0;
  }
  .pdf-zoom{
    display: none;
  }
  .header{
    .header-content{
      .menu{
        .btn{
          padding: 5px 10px;
          font-size: 1em;
        }
      }
      .user{
          font-size:1em;
          margin-top:0px;
          font-weight: normal;
      }
    }
  }
  .tabs{
    margin:10px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    .btn.btn-tab{
      padding: 6px 14px;
      box-shadow: none;
      font-size: 1.2em;
      margin-right: 0px;
      border-radius: 4px;
      margin-bottom:2px;
      border:1px solid $accent-2;
      &:first-child{
        margin-left: 0px;
      }
      &:hover{
        border-bottom: 2px solid $primary;
      }
      &:active{
        border-bottom: 2px solid $accent;
      }
    }
  }
  .tabs-content{
    padding:10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    flex-wrap: wrap;
    .tab-card{
      width:100%;
      min-height: 100px;
      background-color: $accent-2;
      margin-right: 0px;
      margin-bottom: 10px;
      .title{
        font-size: 1.2em;
      }
    }
  }

  .brake{
    .title{
      &:nth-child(1){
        display: none;
        visibility: hidden;
      }
      &:nth-child(5){
        display: none;
        visibility: hidden;
      }
    }
  }

  .course-filters{
    display: flex;
    flex-direction: column;
    .search{
      width:100%;
      margin-bottom: 10px;
      input{
        width: 100%;
      }
    }
    .filter-item{
      display: flex;
      flex-direction: row;
      align-items: center;
      .filter-title{
        font-size: 1.1em;
        color:$main;
        margin-right: 5px;
      }
    }
  }
  .courses-list{
    padding:10px 20px;
    .list-item{
      border-top:0;
      border-bottom:0;
      padding: 0;
      background-color: $accent-2;
      @include shadow-level(2);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      border-radius: 4px;
      margin:10px 0;
      
      .video-icons{
        width:100%;
        .video-image{
          .splash{
            @include shadow-level(0);
            border-radius: 4px 4px 0 0;
          }
          .player-icon{
            position: absolute;
            width:80px;
            top: calc(50% - 40px);
            left: calc(50% - 40px);
          }
        }
      }
      
      .item-description{
        width:100%;
        padding: 10px 10px 0px 10px;
        .course-description{
          min-height: 20px;
          height: auto;
        }
        .videos-ammount{
          margin-top:10px;
        }
      }
  
      .access-course{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        flex-flow: row-reverse;
        color:$main;
        font-weight: bold;
        width:100%;
        text-align: center;
        background-color: $primary;
        border-radius: 0 0 4px 4px;
        padding:5px;
        span{
          display: block;
          font-size:1em;
          color:$background;
        }
        svg{
          width:40px;
          height:40px;
          margin-bottom:0px;
          fill: $background;
          margin-left: 10px;
        }
        .material-icons {
          font-size: 2em;
          padding: 10px;
        }
      }

      .course-completion{
        border-top:1px solid $accent-3;
        padding:10px 0;
        margin-top:10px;
        width:50%;
        .completion-title {
          font-size: 1em;
        }
        .completion{
          font-size:2em;
        }
      }
      .course-certificate{
        border-top:1px solid $accent-3;
        margin-top:10px;
        padding:10px 0;
        width:50%;
      }
    }
    .quiz-mark{
      border-right: 0px !important;
      width:100% !important;
      height:10px !important;
      border-radius: 4px 4px 0 0;
    }
    .video-mark{
      border-right: 0px !important;
      width:100% !important;
      height:10px !important;
      border-radius: 4px 4px 0 0;
    }
    .discussion-mark{
      border-right: 0px !important;
      width:100% !important;
      height:10px !important;
      border-radius: 4px 4px 0 0;
    }
    .reading-mark{
      border-right: 0px !important;
      width:100% !important;
      height:10px !important;
      border-radius: 4px 4px 0 0;
    }
  }

  #VideoItem{
    padding-left:10px;
    padding-right:10px;
    width:100%;
  
    .videoTitle{
      background-color: $primary;
      padding: 5px;
      h1{
        font-size:1.5em;
        margin:0;
        color:$background
      }
    }
    
    .video-footer{
      flex-wrap: wrap;
      .quiz-legend{
        width:100%;
      }
      .video-rating{
        width:100%;
      }
    }
    .bottom-section{
      display: flex;
      flex-wrap: wrap;
      .lesson-comments{
        width:100%;
        min-width: 100%;
        .comments-header{
          font-weight: bold;
          font-size: 1.2em;
        }
        textarea{
          width:100%;
          height:80px;
          border-radius: 4px;
          border:1px solid $accent-3;
        }
        .comment-buttons{
          text-align: right;
        }
      }
      .back{
        width:100%;
        order:2;
      }
    }
  }
  .locked{
    .lock-info{
      padding-left: 10px;
      width: 100%;
    }
  }
  .header-spacer {
    padding-top: 60px;
    width:100%;
    // border:1px solid red;
  }
  .dashcoursesArea{
    min-height: 10rem;
  }
  .dashCourse{
    flex-direction: column;
    height: auto;
    .dashCourseThumb{
      width: 100%;
      height: 200px;
      margin:0 0 0 0;
    }
    .dashCourseDetailsContainer{
      padding: 5px;
      width: 100%;
      .startCourse{
        display: block;
        width: 100%;
        text-align: center;
        padding:10px;
        margin:4px 0;
        font-weight: bold;
        border-radius: 4px;
        background-color: $papergray;
      }
      .removeCourse{
        display: block;
        width: 100%;
        text-align: center;
        padding:10px;
        margin:4px 0;
        font-weight: bold;
        border-radius: 4px;
      }
    }
  }
  .learn-more-courses{
    .course{
      .preview-card{
        position: relative;
      
        .upcoming-product{
          position: relative;
          bottom: 0;
        }
        
        .tooltip-content{
          width: 110%;
          left: calc(125% + 10px);
          display: none;
          visibility: hidden;
        }
      }
    }
  }
  .carousel{
    // display: none;
    width: 100%;
    height: 500px;
    .carousel-inner{
      width: 100%;
      height: 500px;
      .carousel-item{
        height: 500px;
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: rgba(0,0,0,9);
        .carousel-content{
          justify-content: flex-start;
          align-items: flex-end;
        }
        img{
          display: block;
          position: absolute;
          top:0;
          right:50%;
          margin-right: -100%;
          height: 500px;
          width: auto !important;
          opacity: .7;
        }
        .carousel-caption{
          width: 85%;
          
          padding:10px;
          h3{
            font-size: 1.9em;
            @include text-shadow-level(2)
          }
        }
      }
    }
  }
  .course-details-container{
    min-height: auto;
  }
 }