.splasContainer{
  position: fixed;
  z-index: 9000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // background-color: black;
  // background-image: url("../assets/images/splashBkg");
  
  background: url("../assets/images/splashBkg.jpg") center center;
  background-size: cover;
  // transition: all 2s;
  animation: homeSlideInUp 400ms ease-in;
  -webkit-animation: homeSlideInUp 400ms ease-in;
  -moz-animation: homeSlideInUp 400ms ease-in;
  animation-timing-function:linear;
  -webkit-animation-timing-function:linear;

  animation-fill-mode:forwards;
  -webkit-animation-fill-mode: forwards;

  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;

  -webkit-animation-delay:7.5s;
  animation-delay:7.5s;
}

.logoContainer{
  width: 100%;
  // height: 100px;
  position: relative;
  border-top: 1px solid #bdccd4;
  border-bottom: 1px solid #bdccd4;
  background-color: rgba(0,0,0,.3);
  overflow: hidden;
  visibility: hidden;
  animation: logoContainerAnimation 300ms ease-in;
  -webkit-animation: logoContainerAnimation 300ms ease-in;
  -moz-animation: logoContainerAnimation 300ms ease-in;

  animation-timing-function:linear;
  -webkit-animation-timing-function:linear;

  animation-fill-mode:forwards;
  -webkit-animation-fill-mode: forwards;

  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;

  -webkit-animation-delay:1s;
  animation-delay:1s;
}

.companyLogo{
  height:90px;
  position: absolute;
  top:100%;
  left: 100%;
  transform: translate(-50%, 0%);
  animation: logoAnimation 1000ms ease-in;
  -webkit-animation: logoAnimation 1000ms ease-in;
  -moz-animation: logoAnimation 1000ms ease-in;

  animation-timing-function:linear;
  -webkit-animation-timing-function:linear;

  animation-fill-mode:forwards;
  -webkit-animation-fill-mode: forwards;

  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.lastCompanyLogo{
  max-height:80px;
  max-width: 90%;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  animation: LastLogoAnimation 500ms ease-in;
  -webkit-animation: LastLogoAnimation 500ms ease-in;
  -moz-animation: LastLogoAnimation 500ms ease-in;

  animation-timing-function:linear;
  -webkit-animation-timing-function:linear;

  animation-fill-mode:forwards;
  -webkit-animation-fill-mode: forwards;

  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.companyLogoAnimated1{
  -webkit-animation-delay:1.5s;
  animation-delay:1.5s;
}

.companyLogoAnimated2{
  -webkit-animation-delay:2500ms;
  animation-delay:2500ms;
}

.companyLogoAnimated3{
  -webkit-animation-delay:2500ms;
  animation-delay:3500ms;
}

.companyLogoAnimated4{
  -webkit-animation-delay:4500ms;
  animation-delay:4500ms;
}

.companyLogoAnimated5{
  -webkit-animation-delay:5750ms;
  animation-delay:5750ms;
}

@keyframes logoAnimation{
  0%{
    top:100%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  25%{
    top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
  75%{
    top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }

  100%{
    top:-100%;
  left: 50%;
  transform: translate(-50%, -100%);
  }
}

@keyframes LastLogoAnimation{
  0%{
    // top:100%;
    // left: 50%;
    // transform: translate(-50%, 0%);
    opacity: 0;
    visibility: hidden;
  }
  50%{
    opacity: 1;
    visibility: visible;
    // top:50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }
  100%{
    opacity: 1;
    visibility: visible;
    // top:50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }
}

@keyframes logoContainerAnimation{
  0% {
    visibility: hidden;
    height: 1px;
  }
  100% {
    visibility: visible;
    height: 100px;
  }
}


@keyframes homeSlideInUp {
  0% {
    // -webkit-transform: translate3d(0, 0, 0);
    // transform: translate3d(0, 0, 0);
    visibility: visible;
    height: 100%;
  }

  100% {
    // -webkit-transform: translate3d(0, 100%, 0);
    // transform: translate3d(0, -100%, 0);
    visibility: hidden;
    height: 0px;
  }
}