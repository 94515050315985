@import "colors";
@import "header";
@import "shadows";
@import "divider";
@import "rating";
@import "login";
@import "quiz";
@import "widthandheights";
@import "epub";
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

body,
html {
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  color: #5c5c5c;
}

@viewport {
  width: 100vw;
  /*Sets the width of the actual viewport to the device width*/
}

* {
  box-sizing: border-box;
}

.main {
  background-color: $background;
  margin-top: 60px;
}

.btn {
  font-size: 1.2em;
  font-family: Montserrat, arial, sans-serif;
  // text-transform: uppercase;
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
  @include shadow-level(1);
  font-size: 1.2em;
  transition: all 0.25s;
  text-decoration: none;
  -moz-user-select: none;
  -ms-touch-action: manipulation;
  -ms-user-select: none;
  -webkit-user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.42857143;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover {
    -moz-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    @include shadow-level(3);
    transform: translateY(-5px);
  }

  &:active {
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    @include shadow-level(2);
    transform: translateY(0px);
  }
}

.sr-only {
  display: none;
}

.section-tilte {
  padding: 0px 10px;

  h1 {
    margin-bottom: 5px;
  }
}

.main-content {
  padding-top: 60px;
}

.course-section-description {
  padding: 0px 10px;
  font-size: 1.2em;
}

.course-completion {
  display: block;
  // padding: 0px 10px;
  // margin: 10px 0;
  font-size: 1.4em;
  // border:1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    // border:1px solid red;
  }
}

h1 {
  color: $primary;
  font-weight: 400;
  // text-transform: uppercase;
  // font-size: 2.5em;
  // font-family: 'Monsieur La Doulaise', cursive;
  // font-family: 'Playfair Display', serif;
}

h1 {
  &.dash {
    font-family: "Playfair Display", serif;
  }
  &.smaller {
    font-size: 1.8em;
  }
}

h2 {
  color: $primary;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  font-family: Montserrat, arial, sans-serif;
  &.sentence{
    text-transform: none;
  }
}



.materials {
  padding: 10px 0;
}

.tabs {
  margin: 10px 5px 0px 5px;
  display: flex;

  .btn.btn-tab {
    border-radius: 4px 4px 0px 0px;
    padding: 6px 24px;
    box-shadow: none;
    border: 2px solid $accent-3;
    border-bottom: 0px;
    font-size: 1.4em;
    color: $primary;
    margin-right: 10px;

    &.active {
      background-color: $accent;
      border: 2px solid $accent;
      border-bottom: 0px;
      font-weight: bold;
    }

    &:hover {
      background-color: $accent-2;
      border: 2px solid $primary;
      border-bottom: 0px;
      -moz-transform: translateY(0);
      -webkit-transform: translateY(0);
      box-shadow: none;
      transform: translateY(0);
    }

    &:active {
      -moz-transform: translateY(0px);
      -webkit-transform: translateY(0px);
      @include shadow-level(0);
      transform: translateY(0px);
      background-color: $accent;
      border: 2px solid $accent;
      border-bottom: 0px;
      font-weight: bold;
    }

    &:first-child {
      margin-left: 10px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
}

.tabs-content {
  padding: 10px 20px;
  background-color: $accent;
  @include shadow-level(-1);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;

  .tab-card {
    width: 33%;
    min-height: 100px;
    background-color: $accent-2;
    border-radius: 4px;
    margin-right: 10px;
    @include shadow-level(1);

    &:last-child {
      margin-right: 0px;
    }
  }
}

.course-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;

  .filter-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .filter-title {
      font-size: 1.1em;
      color: $main;
      margin-right: 5px;
    }
  }
}

input,
select {
  border-radius: 4px;
  border: 1px solid $accent-3;
  height: 40px;
  font-size: 1em;
  padding: 5px;
  background-color: $background;
  border: 1px solid $secondary;
}

.search {
  position: relative;

  #search-ico {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  input {
    border-radius: 20px;
    border: 1px solid $accent-3;
    height: 40px;
    font-size: 1em;
    padding: 5px 5px 5px 40px;
  }
}

.courses-list {
  padding: 0px 20px;
  margin-top: 10px;
  margin-bottom: 20px;

  .list-item {
    border: 2px solid #dedede;
    border-radius: 7px;
    background-color: $accent-2;
    border-bottom: 1px solid $accent-3;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    position: relative;

    min-height: 250px;

    .lock-info {
      display: none;
    }

    .quiz-mark {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 8px;
      height: 100%;
      background-color: $secondary;
      border-right: 2px solid $accent-2;
      z-index: 2;
    }

    .video-mark {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 10px;
      height: 100%;
      background-color: $accent-4;
      border-right: 2px solid $accent-2;
      z-index: 2;
      border-radius: 7px 0 0 7px;
    }

    .discussion-mark {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 10px;
      height: 100%;
      background-color: $success;
      border-right: 2px solid $accent-2;
      z-index: 2;
      border-radius: 7px 0 0 7px;
    }

    .reading-mark {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 10px;
      height: 100%;
      background-color: $warning;
      border-right: 2px solid $accent-2;
      z-index: 2;
      border-radius: 7px 0 0 7px;
    }

    .video-icons {
      width: 15%;
      // border:1px solid yellow;
      overflow: hidden;
      min-width: 200px;

      .video-image {
        position: relative;
        height: 100%;
        overflow: hidden;
        display: block;

        .splash {
          position: absolute;
          width: auto;
          height: auto;
          // max-height: 250px;
          max-width: 200px;
          @include shadow-level(2);
          left: 10px;

        }

        .player-icon {
          position: absolute;
          display: block;
          width: 80px;
          height: 80px;
          top: calc(50% - 40px);
          left: calc(50% - 40px);
          // top:50%;
          // left:50%;
          opacity: 0.85;
          -moz-transition: all 0.25s;
          -webkit-transition: all 0.25s;
          transition: all 0.25s;
          color: $background;
          text-align: center;
          @include text-shadow-level(2);
          font-size: 5em;
        }

        .completed-course-icon {
          position: absolute;
          top: -10px;
          left: -10px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: $secondary;
          @include shadow-level(3);
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 75%;
          }
        }

        &:hover {
          .player-icon {
            position: absolute;
            width: 80px;
            top: calc(50% - 40px);
            left: calc(50% - 40px);
            opacity: 1;
          }
        }
      }
    }

    .item-description {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 10px;

      // border:1px solid red;
      h1 {
        border-bottom: 1px solid $accent-3;
        width: 100%;
      }

      .course-title {
        font-size: 1.2em;
        font-weight: bold;
        display: flex;
        align-items: center;
      }

      .course-description {
        min-height: 80px;
        font-weight: 300;
      }

      .video-amount-info {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .videos-ammount {
        padding-top: 5px;
        display: inline-flex;
        flex-direction: row;
        // text-align: flex-start;
        justify-content: baseline;
        font-weight: 500;

        // :first-child {
        //   font-weight: 600;
        //   font-size: 18px;
        // }

        // span {
        //   font-weight: 500;
        //   font-size: 10px;
        // }
      }
    }

    .access-course {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: $main;
      font-weight: bold;
      width: 10%;
      text-align: center;
      -moz-transition: all 0.25s;
      -webkit-transition: all 0.25s;
      transition: all 0.25s;
      cursor: pointer;
      min-width: 125px;

      .material-icons {
        font-size: 4em;
        // margin-bottom: 20px;
        padding: 20%;
      }

      &:hover {
        color: $secondary;

        svg {
          fill: $secondary;
        }
      }

      img {
        width: 60%;
        margin-bottom: 10px;
      }

      svg {
        width: 60%;
        height: 60%;
        fill: $main;
        -moz-transition: all 0.25s;
        -webkit-transition: all 0.25s;
        transition: all 0.25s;
      }

      i {
        font-size: 2.5em;
      }
    }

    .course-completion {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: $main;
      font-weight: bold;
      width: 10%;
      text-align: center;
      -moz-transition: all 0.25s;
      -webkit-transition: all 0.25s;
      transition: all 0.25s;
      min-width: 125px;

      .completion-title {
        width: 100%;
        font-size: 0.8em;
        text-align: center;
      }

      .completion {
        color: $main;
        // font-size: 1.5em;
        margin: 20% 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        i {
          font-size: 2.5em;
          padding: 15% 0;
        }
        &.notCompleted{
          display: none;
          visibility: hidden;
        }
      }

      .completion-bar-container {
        display: block;
        background-color: $accent-3;
        width: 90%;
        height: 10px;
        margin: 10px 0;
        border-radius: 2px;

        .completion-bar {
          height: 100%;
          display: block;
          background-color: $success;
        }
      }

      i {
        font-size: 4em;
      }
    }

    .course-certificate {
      width: 15%;
      min-width: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-weight: normal;
      text-align: center;
      color: $accent-3;
      margin-top: 10px;

      img {
        width: 50%;
        margin-bottom: 10px;
      }

      &.ready {
        color: $main;
      }
    }
  }

  .list-item-contrast {
    // border:1px solid red;
    background-color: $main !important;
    background: rgb(50, 50, 50) !important;
    background: linear-gradient(
      90deg,
      rgba(50, 50, 50, 1) 0%,
      rgba($accent-4, 1) 100%
    ) !important;
    color: lighten($accent-2, 100%) !important;

    h1 {
      color: $accent-2;
    }

    .access-course {
      color: $accent-2;

      svg {
        // border:1px solid red;
        fill: $accent-2;
      }
    }
    .completion-title{
      color: $background !important;
    }
  }

  .list-item.indentation-0 {
    margin-top: 20px;
  }

  .list-item.indentation-2 {
    background: #e6e6e6;
    // border-bottom: 1px solid #ffffff;
    padding: 10px 10px 10px 60px;
    line-height: 30px;
    border: 2px solid #dedede;
    background-color: #f7f8fa;
    border-radius: 7px;
    padding: 10px;
    margin: 10px 10px 10px 60px;
    position: relative;
    min-height: auto;

    .video-icons {
      width: 10%;
      min-width: 110px;
    }

    .video-image .splash {
      max-width: 118px;
    }

    .video-mark {
      top: auto;
      left: auto;
      height: 86%;
    }

    .item-description h1 {
      font-size: medium;
      align-items: center;
      align-content: center;

      i {
        vertical-align: middle;
      }
    }

    .item-description .course-description {
      font-size: 1em;
      min-height: 50px;
    }

    .item-description .course-title {
      font-size: 1em;
    }

    .access-course svg {
      width: 35%;
    }

    .course-completion .completion i {
      font-size: 2.5em;
      // padding: 20% 0;
    }
  }

  .list-item.indentation-2:before {
    content: "";
    position: absolute;
    bottom: 50%;
    /* padding of container - 10px */
    left: -60px;
    /* padding of container - 10px */
    width: 60px;
    /* 3 * half height + half of border radius */
    height: calc(150% + 7.5px);
    border-bottom: inherit;
    border-left: inherit;
    border-bottom-left-radius: 15px;
    /* don't overlap items */
    z-index: -1;
  }

  /* arrow */
  .list-item.indentation-2:after {
    content: "";
    position: absolute;
    /* border-width - 1px */
    left: -8px;
    bottom: calc(50% - 6px);
    border-color: transparent;
    border-left-color: inherit;
    border-style: inherit;
    border-width: 7px;
  }

  .list-item.indentation-3 {
    background: #e6e6e6;
    border-bottom: 1px solid #ffffff;
    padding: 10px 10px 10px 60px;
    line-height: 30px;
    border: 2px solid #dedede;
    background-color: #f7f8fa;
    border-radius: 7px;
    padding: 10px;
    margin: 10px 10px 10px 60px;
    position: relative;
    min-height: auto;

    .video-icons {
      width: 10%;
      min-width: 110px;
    }

    .video-image .splash {
      max-width: 118px;
    }

    .video-mark {
      top: auto;
      left: auto;
      height: 86%;
    }

    .item-description h1 {
      font-size: medium;
      align-items: center;
      align-content: center;

      i {
        vertical-align: middle;
      }
    }

    .item-description .course-description {
      font-size: 1em;
      min-height: 50px;
    }

    .item-description .course-title {
      font-size: 1em;
    }

    .access-course svg {
      width: 35%;
    }

    .course-completion .completion i {
      font-size: 0.8em;
      // padding: 20% 0;
    }
  }

  .list-item.indentation-3:before {
    content: "";
    position: absolute;
    bottom: 50%;
    /* padding of container - 10px */
    left: -60px;
    /* padding of container - 10px */
    width: 60px;
    /* 3 * half height + half of border radius */
    height: calc(150% + 7.5px);
    border-bottom: inherit;
    border-left: inherit;
    border-bottom-left-radius: 15px;
    /* don't overlap items */
    z-index: -1;
  }

  /* arrow */
  .list-item.indentation-3:after {
    content: "";
    position: absolute;
    /* border-width - 1px */
    left: -8px;
    bottom: calc(50% - 6px);
    border-color: transparent;
    border-left-color: inherit;
    border-style: inherit;
    border-width: 7px;
  }

  .list-item.indentation-4 {
    background: #e6e6e6;
    border-bottom: 1px solid #ffffff;
    padding: 10px 10px 10px 60px;
    line-height: 30px;
    border: 2px solid #dedede;
    background-color: #f7f8fa;
    border-radius: 7px;
    padding: 10px;
    margin: 10px 10px 10px 60px;
    position: relative;
    min-height: auto;

    .video-icons {
      width: 10%;
      min-width: 110px;
    }

    .video-image .splash {
      max-width: 118px;
    }

    .video-mark {
      top: auto;
      left: auto;
      height: 86%;
    }

    .item-description h1 {
      font-size: medium;
      align-items: center;
      align-content: center;

      i {
        vertical-align: middle;
      }
    }

    .item-description .course-description {
      font-size: 1em;
      min-height: 50px;
    }

    .item-description .course-title {
      font-size: 1em;
    }

    .access-course svg {
      width: 35%;
    }

    .course-completion .completion i {
      font-size: 0.8em;
      // padding: 20% 0;
    }
  }

  .list-item.indentation-4:before {
    content: "";
    position: absolute;
    bottom: 50%;
    /* padding of container - 10px */
    left: -60px;
    /* padding of container - 10px */
    width: 60px;
    /* 3 * half height + half of border radius */
    height: calc(150% + 7.5px);
    border-bottom: inherit;
    border-left: inherit;
    border-bottom-left-radius: 15px;
    /* don't overlap items */
    z-index: -1;
  }

  /* arrow */
  .list-item.indentation-4:after {
    content: "";
    position: absolute;
    /* border-width - 1px */
    left: -8px;
    bottom: calc(50% - 6px);
    border-color: transparent;
    border-left-color: inherit;
    border-style: inherit;
    border-width: 7px;
  }

  .list-item.indentation-5 {
    background: #e6e6e6;
    border-bottom: 1px solid #ffffff;
    padding: 10px 10px 10px 60px;
    line-height: 30px;
    border: 2px solid #dedede;
    background-color: #f7f8fa;
    border-radius: 7px;
    padding: 10px;
    margin: 10px 10px 10px 60px;
    position: relative;
    min-height: auto;

    .video-icons {
      width: 10%;
      min-width: 110px;
    }

    .video-image .splash {
      max-width: 118px;
    }

    .video-mark {
      top: auto;
      left: auto;
      height: 86%;
    }

    .item-description h1 {
      font-size: medium;
      align-items: center;
      align-content: center;

      i {
        vertical-align: middle;
      }
    }

    .item-description .course-description {
      font-size: 1em;
      min-height: 50px;
    }

    .item-description .course-title {
      font-size: 1em;
    }

    .access-course svg {
      width: 35%;
    }

    .course-completion .completion i {
      font-size: 0.8em;
      // padding: 20% 0;
    }
  }

  .list-item.indentation-5:before {
    content: "";
    position: absolute;
    bottom: 50%;
    /* padding of container - 10px */
    left: -60px;
    /* padding of container - 10px */
    width: 60px;
    /* 3 * half height + half of border radius */
    height: calc(150% + 7.5px);
    border-bottom: inherit;
    border-left: inherit;
    border-bottom-left-radius: 15px;
    /* don't overlap items */
    z-index: -1;
  }

  /* arrow */
  .list-item.indentation-5:after {
    content: "";
    position: absolute;
    /* border-width - 1px */
    left: -8px;
    bottom: calc(50% - 6px);
    border-color: transparent;
    border-left-color: inherit;
    border-style: inherit;
    border-width: 7px;
  }
}

.locked {
  background: #e5e5e5;

  .item-description,
  .completion {
    opacity: 0.6;
  }

  .lock-info {
    display: flex !important;
    flex-direction: column;
    width: 30%;
    margin: 0 10px;
    height: auto;
    flex-wrap: nowrap;
    // height: 100% !important;
    // color: $main;
    // font-size: 0.8em;
    // text-align: left;

    .lock-title {
      width: 100%;
      min-height: 40px;
      padding: 0px 0px 5px 0px;
      margin: 0px 0px 5px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid $accent-3;
      text-align: center;
      font-weight: bold;
      line-height: 100%;
    }

    .requirements {
      height: 100%;
      text-align: center;

      ul {
        list-style-type: none;
        padding-left: 0px;
      }

      .material-icons {
        width: 25px;
      }
    }

    .material-icons {
      color: $warning;
      vertical-align: middle;
    }
  }

  img {
    filter: grayscale(95%);
  }

  .access-course:hover {
    color: $main !important;
    cursor: default;
  }
}

.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .pagination-arrow {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      line-height: 0em;
      color: $primary;
      width: 40px;
      height: 40px;
      background-color: $accent-2;
      border-radius: 50%;
      text-align: center;
      font-size: 40px;
      -moz-transition: all 0.25s;
      -webkit-transition: all 0.25s;
      transition: all 0.25s;
      cursor: pointer;

      &:hover {
        color: $background;
        background-color: $primary;
        -moz-transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        @include shadow-level(3);
        transform: translateY(-5px);
      }

      &:active {
        color: $background;
        background-color: $primary;
        -moz-transform: translateY(0px);
        -webkit-transform: translateY(0px);
        @include shadow-level(1);
        transform: translateY(0px);
      }
    }

    .arrow-left {
      margin-right: 10px;
    }

    .arrow-right {
      margin-left: 10px;
    }

    .number-container {
      background-color: $accent-2;
      padding: 0px 20px;
      height: 40px;
      min-width: 40px;
      border-radius: 20px;

      // border:1px solid $accent-2;
      .btn {
        background-color: $accent-2;
        height: 40px;
        color: $primary;
        @include shadow-level(0);

        &:hover {
          @include shadow-level(3);
        }

        &.active {
          background-color: $primary;
          color: $background;
          -moz-transform: translateY(-2px);
          -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
          @include shadow-level(3);
        }
      }
    }
  }
}

#CourseVideos {
  .top-back-area {
    display: block;
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;

    .btn-flat-background {
      color: $main;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      -moz-transition: all 0.25s;
      -webkit-transition: all 0.25s;
      transition: all 0.25s;
      border: 1px solid transparent;
      border-radius: 4px;
      @include shadow-level(0);
      width: auto;

      svg {
        width: 30px;
        height: 30px;
        margin: 0 5px 0 0;
        fill: $main;
        -moz-transition: all 0.25s;
        -webkit-transition: all 0.25s;
        transition: all 0.25s;
      }

      &:hover {
        color: $secondary;
        border: 1px solid transparent;
        border-radius: 4px;

        svg {
          width: 30px;
          height: 30px;
          margin: 0 5px 0 0;
          fill: $secondary;
        }
      }
    }
  }
}

.right-btn-resources{
  align-self: flex-end!important;
  text-decoration: none;

}
.content-type-marker {
  // border:1px solid red;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: $background;
  margin: 5px 0;
}

.VideoItem {
  display: flex;
  flex-direction: column;
  // padding-left: 15%;
  // padding-right: 15%;
  width: 100%;
  // margin-top: 60px;
  
  .videoTitle {
    border-bottom: 1px solid $primary;
    padding: 5px;

    h1 {
      margin: 0;
      font-size: 1.8em;
    }
  }

  .video-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: stretch;
    align-content: center;
    padding-bottom: 10px;
    

    .quiz-legend {
      background-color: $accent-2;
      width: 100%;
      min-height: 40px;
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .video-rating {
      width: auto;
      min-height: 40px;
      padding: 10px;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      justify-content: flex-start;
      white-space: nowrap;
      align-self: flex-start;
      flex: 1;
    }
  }

  .top-back-area {
    display: block;
    margin: 10px 0 0 0;
    display: flex;
    justify-content: flex-end;
  }

  .btn-flat-background {
    color: $main;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -moz-transition: all 0.25s;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
    border: 1px solid transparent;
    border-radius: 4px;
    @include shadow-level(0);
    width: auto;

    svg {
      width: 30px;
      height: 30px;
      margin: 0 5px 0 0;
      fill: $main;
      -moz-transition: all 0.25s;
      -webkit-transition: all 0.25s;
      transition: all 0.25s;
    }

    &:hover {
      color: $secondary;
      border: 1px solid transparent;
      border-radius: 4px;

      svg {
        width: 30px;
        height: 30px;
        margin: 0 5px 0 0;
        fill: $secondary;
      }
    }
  }

  .bottom-section {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .lesson-comments {
      width: 100%;

      .comments-header {
        font-weight: bold;
        font-size: 1.2em;
      }

      textarea {
        width: 100%;
        height: 80px;
        border-radius: 4px;
        border: 1px solid $accent-3;
      }

      .comment-buttons {
        text-align: right;
      }
    }
  }
}

.video {
  position: relative;
  padding-bottom: 52.9%;

  .react-player {
    width: 100%;
    height: 100%;
  }

  & iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0px;
  }
}
.video-trailer {
  position: relative;
  width: 90%;
  padding-bottom: 46.25%;
  margin: 0 auto;
  margin-top: 50px;

  .react-player {
    width: 100%;
    height: 100%;
  }

  & iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0px;
  }
}

.spacerTest {
  height: 800px;
  display: block;
  background-color: $accent;
}

.footer-spacer {
  margin-bottom: 60px;
}

.no-courses-message {
  flex: 1;
  align-items: center;
  text-align: center;
  padding: 10px;

  span {
    font-size: 18px;
  }
}

.loading-message {
  flex: 1;
  align-items: center;
  text-align: center;
  padding: 10px;

  span {
    font-size: 18px;
  }
}

.curriculumn-version-combo {
  label {
    margin-right: 10px;
  }

  align-self: flex-end;
  margin-left: auto;
}

//-----------------------------LANDING PAGE STYLES---------------------------//

.land-container {
  padding: 0;
}

.slider-video {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: url("../assets/images/interlaced.png") center center;
  background-repeat: repeat;
  padding: 20px 0;

  iframe {
    width: 100%;
    height: -webkit-fill-available;
  }
}

.slider-health {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: url("../assets/images/Categories/dark-honeycomb.png");
  background-repeat: repeat;
  background-size: auto;
  padding-top: 65px;

  h1 {
    text-align: center;
    color: #fc0;
    padding-bottom: 40px;
  }

  h2 {
    padding-top: 40px;
    margin-bottom: 0;
    color: white;
    text-align: center;
  }
}


.slider {
  height: fit-content;
  width: 100%;
  overflow: hidden;
  position: relative;
  max-height: 500px;

  .carousel-content{
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    top:0;
    left:0px;
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 500px;
    padding-top:100px;
  }


  .carousel-caption {
    position: relative;
    left: 10%;
    text-align: left;
    width: 60%;
    
  }

  .carousel-caption h3 {
    animation: 2s slide-right;
    animation-delay: 0s;
    font-size: 2.5em;
  }

  .carousel-caption p {
    animation: 2s slide-right;
    font-size: 1em;
  }

  .carousel-caption .btn {
    animation: 2.5s slide-right;
    animation-delay: 0s;
  }

  

  @keyframes slide-right {
    from {
      margin-left: -500px;
      opacity: 0;
    }

    to {
      margin-left: 0%;
      opacity: 1;
    }
  }

  .slider-video {
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: middle;
    width: auto;
    height: 100%;
  }

  h1 {
    text-align: left;
  }

  h2 {
    padding-top: 40px;
    margin-bottom: 0;
    text-align: left;
  }
}

.landing-title {
  text-align: center;
  padding: 40px 0;
  border-bottom: 1px solid #cccccc;
  display: block;
  margin: 0 auto;
  margin-bottom: 50px;
  width: fit-content;
}

.landing-section {
  padding: 60px 0 100px 0;
  display: block;
  width: 100%;

  .carousel {
    padding-bottom: 20px;
  }
  .slider{
    border:1px solid red !important;
  }
  .slider .carousel-caption {
    right: auto;
    bottom: 0;
    top: auto;
    left: 0;
    padding: 10px;
    text-align: center;
    width: 100%;
    position: absolute;

  }

  .carousel-inner {
    flex-wrap: nowrap;
    height: auto;
    display: flex;
    width: 90%;
    margin: 0 auto;
  }

  .carousel-item.active {
    translate: 0;
  }

  .carousel-items {
    position: relative;
    width: 30%;
    display: inline-block;
    margin: 0;
    margin-right: 10px;
    text-decoration: none;
    img {
      border-radius: 10px;
    }
  }

  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    display: inline-block;
  }

  .carousel-indicators {
    bottom: -20px;
  }

  .carousel-caption {
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.6) 40%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.6) 40%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.6) 40%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#d9000000', GradientType=0);
    /* IE6-9 */
    border-bottom: 1px solid #fdc80a;
    padding-top: 40px;

    h3 {
      font-size: 1.5em;
      text-align: center;
      animation: none;
      font-weight: 300;
    }

    .btn {
      animation: none;
      margin: 0 auto;
      font-size: 0.8em;
      margin-bottom: 10px;
    }
  }

  .courses-slider .carousel-items {
    width: 22%;
  }
}

.carousel-small {
  display: none;
  width: 100%;
  a{
    text-decoration: none;
  }

  .course {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;
    text-decoration: none !important;
    cursor: pointer;
  }

  .img-cours{
    width: 100px;
    overflow: hidden;
    position: relative;
    height: 100px;
    background: #1f93e4;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  img {
    width: 100%;
    min-height: auto !important;
    display: inline;
    vertical-align: bottom;
    position: absolute;
  }

  .courseTitle {
    display: inline-block;
    vertical-align: middle;
    color: #5c5c5c;
    font-weight: 500;
    font-size: 1em;
    padding: 10px;
    margin-left: 10px;
    white-space: normal;
    width: 70%;

    p{
      margin: 0;
      white-space: normal;
    }
    h2{
      text-decoration: none;
      white-space: normal;
    }
  }

}

.carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 0;
  // margin-bottom: 20px;

  .courseContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .course {
    height: auto;
    overflow: hidden;
    position: relative;
    margin-right: 5px;
    
    

    img {
      width: 100%;
    }

    .courseTitle {
      width: 100%;

      p {
        color: #ffffff;
        font-weight: 500;
        font-size: 1em;
        text-align: center;
        margin-bottom: 0;
        padding: 10px;
        background: rgba(0, 0, 0, 0.8);
        border-top: 1px solid #fc0;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }

      h2 {
        text-align: center;
        color: #ffffff;
        margin-bottom: 0;
        font-size: 1em;
        padding: 10px;
        background: rgba(0, 0, 0, 0.8);
        border-top: 1px solid #fc0;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }
    }
  }

  .left-gradient {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    text-align: start;
    width: 100px;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc000000', endColorstr='#00ffffff', GradientType=1);
    /* IE6-9 */

    div {
      margin-top: 100%;
      width: auto;
      padding: 20px;
      display: block;
      cursor: pointer;

      img {
        width: 40px;
      }
    }
  }

  .right-gradient {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    text-align: end;
    width: 100px;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#cc000000', GradientType=1);
    /* IE6-9 */

    div {
      margin-top: 100%;
      width: auto;
      padding: 20px;
      display: block;
      cursor: pointer;

      img {
        width: 40px;
      }
    }
  }
}

.course-details {
  font-weight: 400;
  font-size: 1.8em;

  .btn {
    font-size: inherit;
  }
}
.course-details-container{
  min-height: 440px;
}
.category-table {
  width: 100%;

  tr {
    border-bottom: #6c6c6c 1px solid;

    &:first-child {
      border-bottom: #fdc80a 1px solid;
    }
  }

  td {
    padding: 10px;

    &:last-child {
      text-align: center;
    }
  }

  th {
    padding: 10px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1em;
    font-family: Montserrat, arial, sans-serif;

    &:last-child {
      text-align: center;
    }
  }
}

.courseCarousel {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 135px;
  overflow: auto;
}

.course img {
  min-height: 200px;
}

.nonegreenPlay {
  height: 0px;
  position: absolute;
  display: none;
}

.playCourse {
  height: 0px;
  transition: height 4s linear 1s;
}

.greenPlay {
  height: 100px;
  position: absolute;
  background-color: green;
  width: 100%;
  text-align: center;
  color: #fff;
  padding-top: 40px;
  cursor: pointer;
  transition: height 4s linear 1s;
}

.greenPlay span {
  cursor: pointer;
}

.pdfViewer {
  // border:1px solid red !important;
  // background-color: $primary;
  position: fixed;
  bottom: 0px;

  width: 100%;
  height: calc(100% - 60px);

  overflow: scroll;

  #can {
    // canvas {
    // border:1px solid green !important;
    width: auto;
    height: auto;

    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  $zoom: 20;

  @for $i from 0 through $zoom {
    .zoom-#{$i} {
      zoom: ($i) * 0.1;
    }
  }
}

@media print {
  :not(.pdfViewer) {
    display: none;
  }
}

.pdf-header {
  position: fixed;
  height: 60px;
  z-index: 999;
  width: 100%;
  background: #060606;
  color: #ffffff;
  padding: 0px 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 50px;

  .top-back-area {
    position: absolute;
    left: 15px;
    cursor: pointer;
  }

  .header-logo {
    display: none;
    z-index: 400;
    height: 30px;
    position: absolute;
    left: 160px;

    img {
      height: 100%;
    }
  }
}
.zoom-number {
  padding: 0 20px 0 0;
}
.pdf-zoom {
  margin-left: 10px;
  padding: 0 20px 0 0;

  button {
    margin-right: 10px;
    background: none;
    border: none;
    outline: none;
    color: #ffffff;
    float: right;
    opacity: 0.5;
    transition: all 0.2s ease-out;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    &:hover {
      opacity: 1;
    }

    &:active {
      @include shadow-level(-1);
      background-color: lighten($accent-4, 10%);
    }

    span {
      vertical-align: sub;
    }
  }
}

.pdf-pagination a {
  color: #ffffff;
  float: left;
  padding: 3px 10px;
  text-decoration: none;
  margin-right: 5px;
  border-radius: 50px;
  transition: background-color 0.3s;
  cursor: pointer;
}

/* Style the active/current link */
.pdf-pagination a.active {
  background-color: dodgerblue;
  color: white;
}

/* Add a grey background color on mouse-over */
.pdf-pagination a:hover:not(.active) {
  background-color: #14232e;
}

.pager {
  width: 100%;
  position: fixed;
  top: 40%;
  left: 0;
  right: 0;
  list-style: none;
  padding: 0;
  margin: 0 auto;

  button {
    border: none;
    background: none;
    padding: 10%;
    margin: 10px;
    transition: all 1s ease-out;
    outline: 0;

    &:hover {
      background: #f4f4f4;
    }

    span {
      font-size: 4em;
      color: #b2b2b2;
      vertical-align: middle;
    }
  }

  .previous {
    float: left;
  }

  .next {
    float: right;
  }
}

.form-check {
  margin-top: 10px;
  align-items: flex-start;
  align-content: flex-start;
  display: flex;
  flex-direction: row;

  input {
    height: auto;
  }
}

//------------------------END OF LANDING PAGE STYLES------------------------//
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: rgb(29, 29, 29);
  padding: 15px;
  border: 15px solid rgba(29, 29, 29, 0.1);
  border-radius: 100px;
}

.carousel-control-next {
  right: -30px;
  opacity: 0.7;
}
.carousel-control-prev {
  opacity: 0.7;
  left: -30px;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
  opacity: 0;
}

.upcoming-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #323232;
  font-weight: bold;
  width: 10%;
  text-align: center;
  transition: all 0.25s;
  cursor: pointer;
}

.forgot-title {
  font-size: 20px;
  color: #1f93e4;
}

.home-modal p {
  text-align: "left";
}

.reset-body {
  margin-top: "20px";
}

.change-password-link {
  cursor: pointer;
  width: 40px;

  img {
    width: 30px;
  }
}

.badLogin {
  color: #e22929;
  background: transparent;
}

.close-btn {
  background: transparent;
  border: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  outline: none;
}

.close-btn:focus {
  outline: none;
  border: none;
}

.close-btn img {
  height: 20px;
}

.passworText {
  width: 100%;
}

.passwor-button-submit {
  justify-content: center !important;
}

.resetPassBtn {
  color: #b2b2b2 !important;
}

.hintsIconButton {
  position: absolute;
  margin: 10px;
  right: 0;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.hintsIconButton img {
  height: 20px;
  width: auto;
}

.hintsIconButton span {
  display: block;
  color: #77a430;
}

.hintRow {
  width: 100%;
  text-align: center;
  justify-content: center;
  font-weight: 500;
  color: #77a430;
}

.hintRow img {
  width: 20px;
  padding-bottom: 5px;
  padding-right: 5px;
}

#company {
  // border:1px solid red;
  padding-top: 50px;
}

.company-back {
  // margin-top:20px;
  // margin-bottom:20px;
  background: url(../assets/images/company.jpg) rgba(0, 0, 0, 0.8);
  background-size: cover;
  background-position: center center;
  background-blend-mode: multiply;
  background-attachment: fixed;
  color: $background;
}

.audio-player {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  max-width: 350px;
  border-radius: 10px;
  
  padding: 24px;
  box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
  color: $main;
  // background-color: #4d4d4d;
  background: rgb(250, 250, 250);
  background: linear-gradient(0deg, rgba($accent-2, 1) 0%, rgba($background, 1) 100%);
  border:1px solid darken($background, 20%);
  .audio-controls {
    display: flex;
    justify-content: center;
    width: 75%;
    margin: 0 auto 15px;
    .pause {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      padding: 0px !important;
      position: relative;
      background-color: $main;

      background: linear-gradient(
        // 45deg,
        rgba(darken($papergray, 15%), 1) 0%,
        rgba($papergray, 1) 50%,
        rgba(lighten($papergray, 15%), 1) 100%
      );
      
      border: 4px solid lighten($secondary, 5%);
      display: flex;
      justify-content: center;
      align-items: center;
      color: $secondary;
      transition: all 200ms ease;
      @include shadow-level(1);
      &:hover {
        @include shadow-level(2);
        // filter: brightness(1);
        transform: scale(1.05);
      }
      &::before {
        content: "";
        z-index: -2;
        display: block;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background-color: darken($secondary, 5%);
        position: absolute;
        top: -4px;
        left: -4px;
        animation: pulse 1.2s ease infinite;
      }
      &::after {
        content: " ";
        z-index: -2;
        display: block;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background-color: darken($secondary, 10%);
        position: absolute;
        top: -4px;
        left: -4px;
        animation: pulse 1.2s ease infinite;
        animation-delay: 0.3s;
      }
    }
    .play {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      padding: 0px !important;
      position: relative;
      background-color: $main;
      background: linear-gradient(
        rgba(darken($papergray, 15%), 1) 0%,
        rgba($papergray, 1) 50%,
        rgba(lighten($papergray, 15%), 1) 100%
        
      );
      border: 4px solid lighten($success, 5%);
      display: flex;
      justify-content: center;
      align-items: center;
      color: $success;
      transition: all 300ms ease;
      &:hover {
        @include shadow-level(2);
        // filter: brightness(1.1);
        transform: scale(1.05);
      }
    }
  }
}

.audio-player-plugin {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  max-width: 350px;
  padding: 24px;
  color: #ffff;

  .audio-controls {
    display: flex;
    justify-content: center;
    width: 75%;
    .pause {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      padding: 0px !important;
      position: relative;
      background-color: $main;
      background: linear-gradient(
        45deg,
        rgba($main, 1) 0%,
        rgba($accent-4, 1) 70%,
        rgba(73, 152, 217, 1) 100%
      );
      border: 1px solid lighten($accent-4, 20%);
      display: flex;
      justify-content: center;
      align-items: center;
      color: $background;
      transition: all 200ms ease;
      @include shadow-level(1);
      &:hover {
        @include shadow-level(2);
        // filter: brightness(1);
        transform: scale(1.15);
      }
      &::before {
        content: "";
        z-index: -2;
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: lighten($accent-4, 20%);
        position: absolute;
        top: -2px;
        left: -2px;
        animation: pulse 1.2s ease infinite;
      }
      &::after {
        content: " ";
        z-index: -2;
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: lighten($accent-4, 40%);
        position: absolute;
        top: -2px;
        left: -2px;
        animation: pulse 1.2s ease infinite;
        animation-delay: 0.3s;
      }
    }
    .play {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      padding: 0px !important;
      position: relative;
      background-color: #5c5c5c;

      display: flex;
      justify-content: center;
      align-items: center;
      color: $background;
      transition: all 300ms ease;
      &:hover {
        @include shadow-level(2);
        // filter: brightness(1.1);
        transform: scale(1.15);
      }
    }
  }
}

.audio-artwork {
  border-radius: 120px;
  display: block;
  margin: auto;
  height: 200px;
  width: 200px;
}

.audio-track-info {
  text-align: center;
  z-index: 1;
  position: relative;
}

.audio-title {
  font-weight: 700;
  margin-top: 4px;
  margin-bottom: 4px;
  // color: #ffffff;
}

.audio-artist {
  font-weight: 300;
  margin-top: 0;
}

.audio-controls button {
  @include shadow-level(1);
}

// .audio-controls .play svg,
// .audio-controls .pause svg {
//   height: 63px;
//   width: 63px;
// }

@keyframes pulse {
  0% {
    transform: scale(1, 1);
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }

  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

// .audio-controls .play svg,
// .audio-controls .play svg {
//   height: 63px;
//   width: 63px;
//   border:1px solid red;
// }

.audio-controls path {
  fill: #ffffff;
}

.audio-track {
  height: 5px;
  -webkit-appearance: none;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
  //background: #3b7677;
  //transition: "background 0.2s ease";
  cursor: pointer;
}

.form-group {
  .form-label {
    margin-bottom: 0rem;
    margin-top: 0.6rem;
  }
}
.text-right {
  text-align: right !important;
}

.menu .menu-item .menu-second {
  border-top: none;
  border-radius: 4px;
  background-color: darken($main, 50%);
}
.menu-inner {
  border-radius: 4px;
  min-width: 150px;
  @include shadow-level(2);
  .menu-item {
    border-bottom: 1px solid lighten($main, 10%);
    padding: 5px 0;
    &:last-child {
      border-bottom: 0;
    }
    a {
      text-decoration: none;
    }
  }
}
.context-menu {
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  padding: 5px 0 5px 0;
  width: 150px;
  height: auto;
  margin: 0;
  /* use absolute positioning  */
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
}
// .anotationMarkClass {
//   fill: darkseagreen;
//   position: relative;
//   &::after {
//     font-family: "Material Icons";
//     display: block;
//     position: absolute;
//     content: "<circle fill='red' width='12px' height='12px'/>";
//     top: 0;
//     left: 0;
//     color: red;
//     border: 1px solid red;
//     width: 15px;
//     height: 15px;
//   }
// }

.contextMenu {
  margin: 20px;
  position: absolute;
  background-color: white;
  min-width: 150px;
  min-height: 180px;
  top: 30%;
  left: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    height: 20px;
    margin: 10px;
    text-align: center;
    width: 100%;
    // border-bottom: solid;
  }
}
.commenModal {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  margin: 10px;
  padding: 10px;
  z-index: 999;

  .container {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 20px 40px;
    background-color: white;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    .modal-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      h2 {
        margin-bottom: 0;
      }
      .modal-close {
        height: 45px;
        border: 0;
        padding: 10px;
        background: transparent;
        color: #6c6c6c;
        cursor: pointer !important;

        &:hover {
          cursor: pointer;
          color: rgb(36, 36, 36);
        }
      }
    }
    .highlight-modal {
      color: #000;
      padding: 10px 0;
    }

    .commentText {
      display: flex;
      flex-flow: column;
      align-items: top;
      width: 100%;
      margin-top: 20px;

      textarea {
        min-height: 70px;
        width: 100%;
        vertical-align: top;
      }
    }
    .buttonCommands {
      flex-wrap: wrap;
      text-align: end;
      margin-top: 10px;
      a {
        margin: 10px;
      }
    }
  }
}

// .list-item .item-first-class {

.certificatesContainer{
  min-height:  calc(100vh - 280px);
}
    

.certificatesBorders {
  // border:3px dotted green;
  border: 20px solid white;
  border-image-source: url("../assets/images/secBorder-01.png");
  border-image-slice: 20 20;
  border-image-repeat: round;
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  color-adjust: exact !important; /*Firefox*/
}
.secBack {
  background: url(../assets/images/svg/secbkg.svg) rgba(255, 255, 255, 0.5);
  background-size: cover;
  background-position: center center;
  // secbkg.svg
  position: relative;
  &::before {
    display: block;
  }
}
.sec-item {
  border: 1px solid $lightgray;
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: 10px;
}
.exp-date {
  font-size: 0.8em;
}

@media screen and (max-width: 890px) {
  .commenModal .container {
    width: 90%;
  }
}

// Dashboard css
.dashTabs {
  // font-size: 1.2em;
  text-decoration: none !important;
  color: $primary;
  margin-right: 20px;
  padding: 10px 0 0 0;
  display: block;
  cursor: pointer;
  &:hover {
    color: $accent-4;
  }
  &.active {
    border-bottom: 4px solid $primary;
  }
}
.dashcoursesArea {
  padding: 20px 0;
  // min-height: 25rem;
  border-bottom: 1px solid $papergray;
  min-height:  calc(100vh - 250px);
}

.dashCourse {
  min-height: 100px;
  height: 120px;
  border-radius: 2px;
  margin-top: 20px;
  @include shadow-level(1);
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  overflow: hidden;

  .dashCourseThumb {
    display: block;
    width: 210px;
    height: 100%;
    margin: 0 10px 0 0;
    overflow: hidden;
    a {
      width: 100%;
      // width: calc(100% - 220px);
      height: 100%;
      display: block;
      position: relative;
      &::after {
        font-family: "Material Icons";
        content: "\e037";
        font-size: 60px;
        color: $background;
        display: flex;
        @include text-shadow-level(3);
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: rgba($main, 0.1);
        transition: all 0.2s;
      }
      &:hover {
        position: relative;
        &::after {
          background-color: rgba($main, 0.5);
          font-size: 72px;
        }
      }
    }
    img {
      width: 100%;
    }
  }
  .dashCourseDetailsContainer {
    // width: 100%;
    width: calc(100% - 220px);
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .dashCourseDetails {
      display: flex;
      .courseText {
        // min-width: 85%;
        width: 100%;
        // border:1px solid red;
        h6 {
          line-break: strict;
        }
        p {
          font-size: 14px;
          // line-height: 105%;
          color: lighten($main, 40%);
          font-weight: lighter;
          margin-bottom: 0px;
          max-height: 36px;
          overflow: hidden;
          position: relative;
   
        }
      }
      .courseCert {
        width: 15%;
      }
    }
    .courseFooter {
      padding: 0 5px 5px 0;
      .startCourse {
        color: $success;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          color: $secondary;
        }
      }
      .removeCourse {
        color: $lightgray;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 14px;
        &:hover {
          color: $secondary;
        }
      }
      .percentComplet {
        font-size: 10px;
        line-height: 100%;
        color: $lightgray;
      }
      .progressBar {
        width: 100%;
        height: 10px;
        background-color: $papergray;
        display: block;
        padding:2px;
        border-radius: 4px;
        .progressMade {
          display: block;
          background-color: $success;
          height: 6px;
          border-radius: 3px;
        }
      }
    }
  }
}

.title {
  // background-color: $primary;
  color: $main;
  // border-radius: 4px 4px 0 0;
  border-bottom: 1px solid $accent-3;
  padding: 5px 2px;
  font-size: 1.2em;
  // text-align: center;
  font-weight: 600;
}

.tab-link {
  display: block;
  border-bottom: 1px solid $accent-3;
  padding: 8px 10px;
  color: $main;
  font-weight: 400;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: $primary;
    color: $background;
    border-bottom: 1px solid $primary;
  }
}

.card {
  position: relative;
  width: 100%;
  min-height: 100px;
  background-color: $background;
  border-radius: 2px;
  @include shadow-level(1);
}

.dashPrevThumb {
  display: block;
  width: 100%;
  // height: 200px;
  padding-bottom: 60%;
  // margin:0 10px 0 0;
  overflow: hidden;
  position: relative;
  a:not(.nopreview) {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    &::after {
      font-family: "Material Icons";
      content: "\e037";
      font-size: 60px;
      color: $background;
      display: flex;
      @include text-shadow-level(3);
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: rgba($main, 0.1);
      transition: all 0.2s;
      opacity: .65;
    }
    &:hover {
      position: absolute;
      &::after {
        background-color: rgba($main, 0.5);
        font-size: 72px;
      }
    }
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.LNcourseTitle {
  // border:1px solid red;
  overflow: hidden;
  height: 62px;
  h5 {
    border-bottom: 0px !important;
  }
  cursor: pointer;
}
.LNcourseDesc {
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
}

.whiterow{
  background-color: #fff;
  color: #000;
  padding: 10px;
  margin-left: 1px;
  margin-right: 1px;
}

.preview-card{
  position: relative;
  overflow: visible;
  min-height: 416px;

  .upcoming-product{
    position: absolute;
    bottom: 0;
  }
  
  .tooltip-content{
    visibility: hidden;
    position: absolute;
    z-index: 2;

    top: -20px;
    left: calc(120% + 10px);
    transform-origin: top left;
    
    width: 90%;

    @include shadow-level(2);
    
    background-color: $background;
    // color: #fff;
    border-radius: 6px;
    padding: 30px 20px;
    
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;



    .tooltip_title{
      font-size: 1.2em;
      font-weight: bold;
    }
    .tooltip_description{
      line-height: 120%;
      font-size: .9em;
    }
    .tooltip-detail{
      display:flex;
      justify-items: flex-start;
      align-items: flex-start;
      font-size: .9em;
      .material-icons{
        color: $success;
        margin:0 5px 0 0;
      }
    }

    &::after{
      content: "";
      position: absolute;
      top: calc(50% - 5px);
      left: -5px;

      width: 0; 
      height: 0; 
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent; 
      
      border-right:5px solid $background; 
      
    }
  }
   
  &:hover{
    .tooltip-content{
      visibility: visible;
      opacity: 1;
      
    }
  }
}




  .learn-more-courses{
    .course{
      &:nth-child(4n){
        .preview-card{
          .tooltip-content{
            left: auto;
            right: calc(100% + 10px);
            transform-origin: top left;        
            &::after{
              content: "";
              position: absolute;
              top: calc(50% - 5px);
              left: auto;
              right:-10px;
        
              width: 0; 
              height: 0; 
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent; 
              border-right:5px solid transparent; 

              border-left:5px solid $background; 
              
            }  
          }
        }
      } 
    }
  }

.accordion-button{
  background-color: $papergray !important;
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: flex-end !important;
  color: $primary !important;
  &::after{
    margin-right: 5px !important;
    color: $primary !important;
    margin-left: 0px !important;
  }
  &.collapsed{
    color: $primary !important;
    &::after{
      margin-right: 5px !important;
      margin-left: 0px !important;
      color: $primary !important;
    }
  }
}
.cursorPointer{
  cursor: pointer;
}

.adminLicenses{
  min-height: 35rem;
  .container{
    .Productlicense{
      border-top:1px solid $accent-3;
      padding:10px 5px;
      

      input {
        border: 1px solid $accent-3;
      }
    }
    .Productlicense:nth-child(1){
      border-top:0;
    }
    .Productlicense:nth-child(even){
      background-color: $papergray;
    }
    .Productlicense:nth-child(odd){
      background-color: $background;
    }
  }
}

.landing-Section-Header{
  width: 100%;
  padding: 15px 10px;
  background-color: #323232;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $papergray;
  background-image: url("../../public/wood_bkg.jpg");
  background-size: cover;
  h1{
    color: $papergray;
    margin: 0;
    font-size: 1.8em;
    font-weight: bold;
  }
  p{
    margin: 0;
    font-weight: lighter;
  }
}
.landing-Section-ec-banner{
  width: 100%;
  padding: 0px 10px;
  background-color: #323232;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $papergray;
  background-image: url("https://www.eleadertech.com/hero-bg.png");
  background-size: cover;
}

#right-btn-top{
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */
  text-decoration: none;
}
#right-btn-top:hover {
  color: #555; /* Add a dark-grey background on hover */
  text-decoration: underline;
}

.passwordCheckList{
  display: flex;
  flex-direction: column;
  justify-content:left;
  align-items: start;
  margin-top: 20px;


  li{
    display: flex;
    justify-content: start;
    align-items: start;
    margin: 5px 0;


    &.valid{
      margin-right: 2px;
      .icon {
        color: #008000;
        position: relative;
        width: 22px; 
        height: 22px; 
        border: 1px solid #008000;
        border-radius: 50%;
        text-align: center;
        padding-bottom: 1px;
        margin-right: 10px;
        &::before{
          position: relative;
          content: "\2713";
        }
      }
    }

    &.invalid{
      margin-right: 2px;
      .icon {
        color: #FF0000;
        position: relative;
        width: 22px; 
        height: 22px; 
        border: 1px solid #FF0000;
        border-radius: 50%;
        text-align: center;
        padding-bottom: 1px;
        margin-right: 10px;
        &::before{
          position: relative;
          content: "X";
        }
      }
    }
  }

}

.adCardElt{
  width: 100%;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  // background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../../public/dealership_showroom.jpg');
  background-position: center center;
  background-size: cover;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  position: relative;
  padding: 20px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
  img{
    width: 100%;
    max-width: 200px;
  }
}

.adCardLR{
  width: 100%;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  // background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../../public/DavidConference.jpg');
  background-position: center center;
  background-size: cover;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  position: relative;
  padding: 20px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
  img{
    width: 100%;
    max-width: 200px;
  }
}

.adCardDI{
  width: 100%;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  // background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../../public/bml.png');
  background-position: top center;
  background-size: cover;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  position: relative;
  padding: 20px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
  img{
    width: 100%;
    max-width: 200px;
  }
}

.adCardYOKC{
  width: 100%;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  // background: linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) ), url('../../public/YOKC_BKG.png');
  background-position: center center;
  background-size: cover;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  position: relative;
  padding: 20px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
  img{
    width: 100%;
    max-width: 200px;
  }
}

.mabkg{
  background-color: $papergray;
  background-image: url('../../public/lineBkg.gif');
}
.LRbkg{
  background-color: $main;
  background-image: url('../../public/LRbkg.jpg');
  background-size: 100% cover;
  background-position: center center;
}

.horizontal{
  width: 100%;
  .card{
    padding-left: 21%;
    min-height: 170px;
    
    &.preview-card {
      .tooltip-content{
        display: none;
        visibility: hidden;
        
      }
      &:hover{
        .tooltip-content{
          visibility: visible;
          opacity: 1;
          
        }
      }
    }
    .dashPrevThumb {
      display: block;
      width: 20%;
      padding-bottom: 12.5%;
      overflow: hidden;
      position: absolute;
      top:5px;
      left:5px;
    }
    .LNcourseTitle{
      height: auto;
      margin-bottom: 0;
    }
    .LNcourseDesc{
      height: auto;
    }
    .btn{
      text-align: left;
      display: inline-block;
      width: auto !important;
      margin:0 5px 0 0; 
      position: relative !important;
    }
    #enrolled-session-login{
      // border:1px solid red;
      height: auto !important;
    }
    // .upcoming-product{
    //   border:1px solid red;
    // }
  }
}
caption{
  padding-top: 0px;
  padding-bottom: 0px;
}

.btn.disabled{
  cursor: not-allowed;
  background-color: $accent-3;
  color: $background;
}

.image-zoom-modal .modal-dialog {
  // max-width: 90%; /* Set a standard width for the modal */
  max-height: 95vh; /* Set a standard height for the modal */
  margin: auto; /* Center horizontally */
  
}

.image-zoom-modal .modal-content {
  max-height: 95vh; /* Match the modal's height */
  //width: 90%; /* Match the modal's width */
  //display: flex; /* Enable content-based resizing */
  //align-items: center; /* Align content vertically */
  //justify-content: center; /* Center content horizontally */
  overflow: auto; /* Prevent content overflow */
  display: block !important;
  position: relative;
}
.modalCloseBtn{
  border-radius: 50%;
  background-color:$main;
  width:30px;
  height:30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top:5px;
  right: 0px;
  margin-left:auto;
}
.lockedModule{
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding:40px;
}
.nav-item:hover{
  background-color: $accent-3;
  color: $background;

}
.courseNavigationRightBar {
  overflow: hidden; /* Prevent content overflow during transitions */
  transition: all 1s ease; /* Suave transición de ancho */
  width: 300px; /* Ancho inicial (expandido) */
  overflow-x: visible;
  // overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.courseNavigationRightBar.expanded {
  width: 300px; /* Ancho máximo (expandido) */
  min-width: 300px;
  transition: all 1s ease; /* Suave transición de ancho */
  transform: translateX(00px);
  
}

.courseNavigationRightBar.colapsed{
  width: 0;  /* Ancho mínimo (colapsado) */
  min-width: 0;
  transition: all 1s ease; /* Suave transición de ancho */
  transform: translateX(310px);
  
}
.courseItemContainer{
  min-height:  calc(100vh - 250px);
  // border: 1px solid red;
}
.courseNavigationRightBarController{
  width:100%;
  height: 40px;
  display: flex;
  position:relative;
  @include shadow-level(1)
  
}
.courseNavigationRightBarController button{
  // position: absolute;
  // left:-40px;
  top:5px;
  width: 100%;
  border-radius: 0px;
  border-right: 0px !important;
  border-left: 0px !important;
  // border:1px solid green !important;
}

@import "mobile";
@import "modal";
@import "courseSplashVid";
@import "splash";