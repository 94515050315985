$primary: #4d4d4d;
$secondary: #ffcc00;
$success: #56a500;
$warning: #EF1315;
$background: #ffffff;
$accent-2: hsl(0, 0%, 95%);
$accent-3: #bebebe;
$accent-4: #0b4b7f;
$accent-5: #00a99d;
$accent-6: #714fd5;
$accent: lighten($accent-4, 60%);
$main: #323232;
$lightgray:#cccccc;
$papergray  :#F5f5f5;




$colors:("-primary", $primary),
        ("-secondary", $secondary),
        ("-accent", $accent),
        ("-success", $success), 
        ("-warning", $warning),
        ("-background", $background), 
        ("-accent2", $accent-2), 
        ("-accent3", $accent-3),
        ("-accent4", $accent-4),
        ("-accent5", $accent-5), 
        ("-accent6", $accent-6), 
        ("-lightgray", $lightgray), 
        ("-papergray", $papergray), 
        ("-main", $main);

@each $colors, $color in $colors {
  .text#{$colors} {
    color: $color !important;
  }

  .bkg#{$colors} {
    background-color: $color;
  }
  .bkg#{$colors}-light {
    background-color: lighten($color, 10%);
  }

  .line#{$colors} {
    border: 1px solid $color !important;
  }
  .border-b#{$colors} {
    border-bottom: 1px solid $color !important;
  }
  .border-t#{$colors} {
    border-top: 1px solid $color !important;
  }
  .border-r#{$colors} {
    border-right: 1px solid $color !important;
  }
  .border-l#{$colors} {
    border-left: 1px solid $color !important;
  }
}

@each $colors, $btn in $colors {
  .btn.btn#{$colors} {
    background-color: $btn;
    color: $background;
    // border: 1px solid $btn + #101010;
    border: 1px solid lighten($btn, 10%);
    &:active {
      background-color: lighten($btn, 10%);
      border: 1px solid lighten($btn, 10%);
    }
    &:hover {
      background-color: lighten($btn, 10%);
      border: 1px solid lighten($btn, 10%);
    }
    &:focus {
      background-color: lighten($btn, 10%);
      border: 1px solid lighten($btn, 10%);
    }  
  }
  .btn.btn-flat#{$colors} {
    background-color: $btn;
    color: $background;
    border: 0px solid lighten($btn, 10%);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 1px 2px rgba(0, 0, 0, 0);
    &.btn-sm{
      padding:4px;
      font-size: medium;
    }
    &:active {
      background-color: lighten($btn, 10%);
      border: 0px solid lighten($btn, 10%);
    }
    &:hover {
      background-color: lighten($btn, 10%);
      border: 0px solid lighten($btn, 10%);
      -moz-transform: translateY(0px);
      -webkit-transform: translateY(0px);
      box-shadow: 0 0px 0px rgba(0, 0, 0, 0.19), 0 0px 0px rgba(0, 0, 0, 0.23);
      transform: translateY(0px);
    }
    &:focus {
      background-color: lighten($btn, 10%);
      border: 0px solid lighten($btn, 10%);
    }
  }
  .btn.btn-outlined#{$colors} {
    border: 2px solid $btn;
    color: $background;
    border-radius: 100px;
    text-transform: initial;
    padding: 5px 24px;

    &:active{
      border: 2px solid lighten($btn, 10%);
    }
    &:hover{
      border: 2px solid lighten($btn, 10%);
    }
    &:focus{
      border: 2px solid lighten($btn, 10%);
    }
  }
}

.bkg-accent-4{
  background: #293241;
  color: white;
  h2{
    color: white;
  }
  h1{
    color: #fc0;
  }
}