//
// Paper Shadows
//
// --------------------------------------------------
@mixin shadow-level($level:1) {
  @if $level==-1 {
      box-shadow: inset 7px 7px 15px -7px rgba(0, 0, 0, 0.7);
  }
  @else if $level==0 {
      box-shadow: none;
  }
  @else if $level==1 {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  @else if $level==2 {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  @else if $level==3 {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  @else if $level==4 {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  @else if $level==5 {
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

.shadow-down {
  @include shadow-level(-1);
}

.shadow-1 {
  @include shadow-level(1);
}

.shadow-2 {
  @include shadow-level(2);
}

.shadow-3 {
  @include shadow-level(3);
}

.shadow-4 {
  @include shadow-level(4);
}

.shadow-5 {
  @include shadow-level(5);
}

@mixin text-shadow-level($level:1) {
  @if $level==-1 {
      text-shadow: 0 -2px -2px rgba(0, 0, 0, 0.7);
  }
  @else if $level==0 {
      text-shadow: none;
  }
  @else if $level==1 {
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  @else if $level==2 {
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  @else if $level==3 {
      text-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  @else if $level==4 {
      text-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.23);
  }
  @else if $level==5 {
      text-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

.txt-shadow-1 {
  @include text-shadow-level(1);
}

.txt-shadow-2 {
  @include text-shadow-level(2);
}

.txt-shadow-3 {
  @include text-shadow-level(3);
}

.txt-shadow-4 {
  @include text-shadow-level(4);
}

.txt-shadow-5 {
  @include text-shadow-level(5);
}
