.list-item {
  // border:1px solid red !important;
  &.item-first-class{
    // background-color: $background !important;
    margin-bottom:40px !important;
    border:0px !important;
    border-radius: 0px 0px 0px 0px !important;
    border-bottom:2px solid #dedede;
    padding-bottom:15px !important;
    display: flex;
    // flex-direction: column !important;
    flex-wrap:wrap !important;
    // align-items: baseline !important;
    // justify-items: flex-start !important;
    

    .video-mark {
      position: absolute !important;
      top: calc(100% - 10px) !important;
      left: -0px !important;
      width: 100% !important;
      height: 10px !important;
      background-color: $accent-4;
      border-right: 2px solid $accent-4;
      border-left: 2px solid $accent-4;
      z-index: 2;
      border-radius: 0 0 0 0 !important;
    }
    .video-icons {
      width: 100% !important;
      overflow: hidden;
      position: relative;
      margin-bottom: 10px;
      
      padding-bottom: 36.66%;
      height: 0px;
      overflow: hidden;;
      display: flex !important;
      justify-content: center;
      
      .video-image {
        display: block;
        width: 100%;
        max-width: 800px;
        padding-bottom: 36.6%;
        overflow: hidden;
        @include shadow-level(2);

        .splash {
          position: absolute;
          display: block;
          min-width: calc(100% + 10px) !important;
          left: -10px !important;
          top:-60px;
        }

        .player-icon {
          position: absolute;
          width: 70px;
          top: calc(50% - 35px);
          left: calc(50% - 35px);
          opacity: 0.8;
          -moz-transition: all 0.25s;
          -webkit-transition: all 0.25s;
          transition: all 0.25s;
        }
      }
    }

    .item-description{
      width: 100% !important;
      h1{
        text-align: center;
      }
      .course-description {
        min-height: 20px !important;
        font-weight: 300;
        margin-bottom: 0px;
      }
    }

    .course-completion {
      flex-direction: row !important;
      align-items: center;
      justify-content: flex-start;
      text-decoration: none;
      color: $main;
      font-weight: bold;
      width: auto !important;
      height:auto !important;
      text-align: left;
      margin:0px !important;
      padding-bottom: 0px !important;
      .completion-title {
        width: 100%;
        font-size: .8em;
        text-align: left;
        margin:0px !important;
        padding:0px !important;
      }
      
      .completion {
        color: $main;
        font-size: .9em !important;
        margin: 0px !important;
        width: 100%;
        display: flex;
        flex-direction: row !important;
        justify-content: flex-start;
        align-items: center;
        margin:0px !important;
        padding:0px !important;
        i {
          font-size: 1em !important;
          padding: 15% 0;
        }
        &.notCompleted{
          display: flex !important;
          visibility: visible !important;
          // color: $background !important;
          color: $warning;
          border-radius: 4px;
          padding:5px 10px 5px 5px !important;
          margin-bottom: 0px !important;
          @include shadow-level(1);
          border:1px solid $warning;
          background-color: $background !important;
        }
      }

      .completion-bar-container {
        display: block;
        background-color: $accent-3;
        width: 90%;
        height: 10px;
        margin: 10px 0;
        border-radius: 2px;

        .completion-bar {
          height: 100%;
          display: block;
          background-color: $success;
        }
      }

      i {
        display: inline-block;
        height: auto !important;
        font-size: 1.6em !important;
        margin:0px !important;
        padding:0px !important;
      }

      &.notCompletedSpacer{
        display: none !important;
        visibility: hidden !important;
      }
    }

    .access-course {
      display: flex;
      flex-direction: row-reverse  !important;
      align-items: center !important;
      font-size: 1.2em;
      justify-content: center;
      text-decoration: none;
      color: $main;
      font-weight: bold;
      width: auto !important;
      text-align: center;
      cursor: pointer;
      padding:0px 10px;
      margin-bottom: -10px !important;
      border-radius: 4px 4px 0 0;
      
      .material-icons {
        font-size: 1.2em !important;
        padding: 0% !important;
      }

      &:hover {
        color: $secondary;

        svg {
          fill: $secondary;
        }
      }

      img {
        width: 60%;
        margin-bottom: 10px;
      }

      svg {
        width: 60%;
        height: 60%;
        fill: $main;
        -moz-transition: all 0.25s;
        -webkit-transition: all 0.25s;
        transition: all 0.25s;
      }

      i {
        font-size: 2.5em;
      }
    }
  } 
}